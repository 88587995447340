export const openLeadokuSubscription = (client, setIsLoading, period) => {
  const cbInstance = (window as any).Chargebee.getInstance();
  cbInstance.setCheckoutCallbacks(() => {
    return {
      success: () => {
        setIsLoading(true);
        // TODO refactor GA events
        try {
          (window as any).dataLayer?.push({event: 'success_payment'});
        } catch (e) {
          console.error(e);
        }
      },
    };
  });
  const cart = cbInstance.getCart();
  const product = cbInstance.initializeProduct(period === 'monthly' ? 'leadoku-starter' : 'leadoku-starter-yr');
  cart.setCustomer({
    first_name: client.name,
    email: encodeURIComponent(client.email),
  });
  cart.replaceProduct(product);
  cart.proceedToCheckout();
};
