import {message} from 'antd';

import {BillingService, CONSTANTS} from '../../services';

export const SET_CHARGEBEE_INSTANCE = 'SET_CHARGEBEE_INSTANCE';

export const chargebeeActions = {
  setChargebeeInstance,
  getPortalSession,
  activateRegularPlan,
};

function setChargebeeInstance() {
  return (dispatch: any) => {
    const instance = window.Chargebee.init({
      site: CONSTANTS.chargebee,
      enableGTMTracking: true,
    });
    dispatch(set(instance));
  };

  function set(instance) {
    return {type: SET_CHARGEBEE_INSTANCE, instance};
  }
}

function getPortalSession() {
  return async () => {
    return BillingService.getPortalSession()
      .then(response => {
        return JSON.parse(response.data);
      })
      .catch(_ => null);
  };
}

function activateRegularPlan(updateLoading, period) {
  return async () => {
    const isUpdatingState = updateLoading && typeof updateLoading === 'function';
    try {
      isUpdatingState && updateLoading(true);
      const cbInstance = (window as any).Chargebee.getInstance();
      const response = await BillingService.activateRegularPlan(period);
      cbInstance.openCheckout({
        hostedPage: () => {
          return new Promise(resolve => resolve(response.data));
        },
        success() {
          // TODO refactor GA events
          try {
            (window as any).dataLayer?.push({event: 'success_payment'});
          } catch (e) {
            console.error(e);
          }
        },
      });
    } catch (e) {
      message.error("Sorry, something goes wrong, can't activate regular plan.");
    } finally {
      isUpdatingState && updateLoading(false);
    }
  };
}
