import {Alert, Button, Form, Icon, Input, Modal, Row, Tooltip, Dropdown, Menu} from 'antd';
import {ErrorMessage, Formik} from 'formik';
import React, {useState} from 'react';
import {isMobile} from 'react-device-detect';

import {Client, User} from '@growth-x/types';
import {AdminFeatureIcon, brandConfig} from '@growth-x/ui';

import {InfoIcon} from '../';
import {STRINGS} from '../..';
import {SwitchRow} from '../campaignDetails/common';
import {ConfirmationModal} from '../confirmationModal';
import {UserRequirementsPopover} from './userRequirementsPopover';
import {UserRequirementsTooltip} from './userRequirementsTooltip';

import './userEditModal.css';

interface UserEditModalProps {
  user: User;
  users: User[];
  client: Client;
  members: any;
  visible: boolean;
  loading: boolean;
  error: string;
  handleOk: (user: User, isNew: boolean) => void;
  handleCancel: () => void;
  cleanup: () => void;
  archiveUser: (id: string | number) => void;
  unarchiveUser: (id: string | number) => void;
}

export const ShortNewUserModal = ({
  handleOk,
  user,
  cleanup,
  handleCancel,
  users,
  client,
  members,
  archiveUser,
  unarchiveUser,
  visible,
  loading,
  error,
}: UserEditModalProps) => {
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const isFirstUserForClient = users.length === 0;
  const handleValidate = (values: any) => {
    const errors: any = {};
    if (!values.email) {
      errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Must be an email';
    }

    return errors;
  };

  const handleSubmit = async (values: any) => {
    handleOk(
      {
        ...user,
        ...values,
      },
      false
    );
  };

  const onClose = () => {
    setConfirmationVisible(true);
    cleanup();
    handleCancel();
  };
  const onCloseConfirmModal = () => {
    setConfirmationVisible(false);
  };
  const onCloseModals = () => {
    setConfirmationVisible(false);
    cleanup();
    handleCancel();
  };

  const isLockCreateTeamMember = email => {
    return client.email === email || users.some(u => u.email === email) || members.some(m => m.email === email);
  };

  const handleChangeEmail = (value, setFieldValue) => {
    setFieldValue('email', value);
    setFieldValue('create_team_member', !isLockCreateTeamMember(value));
  };

  const handleAction = async ({key}: any) => {
    if (key === 'archive') {
      archiveUser(user.id);
    } else if (key === 'unarchive') {
      unarchiveUser(user.id);
    }
  };

  const getMenu = () => {
    return (
      <Dropdown
        overlay={
          <Menu onClick={handleAction}>
            {!user.archived && (
              <Menu.Item key="archive">
                Archive <InfoIcon message={STRINGS.tooltip_archive_user} />
              </Menu.Item>
            )}
            {!!user.archived && (
              <Menu.Item key="unarchive">
                <AdminFeatureIcon /> Unarchive
              </Menu.Item>
            )}
          </Menu>
        }
        trigger={['click']}
      >
        <div>
          <Icon type="more" style={{color: 'var(--color-tooltip-icon)'}} className="ant-modal-close-icon" />
        </div>
      </Dropdown>
    );
  };

  return (
    <Modal
      visible={visible || loading}
      onCancel={user.id ? null : onClose}
      footer={null}
      maskClosable={false}
      title="User Details"
      className="user-edit-modal"
      closeIcon={user.id ? getMenu() : null}
    >
      <ConfirmationModal visible={confirmationVisible} onOk={onCloseModals} onCancel={onCloseConfirmModal} />
      <Formik
        initialValues={{
          email: user.email,
          create_team_member: user.create_team_member,
          basic_linkedin_sub: user.basic_linkedin_sub,
        }}
        enableReinitialize={true}
        validate={handleValidate}
        onSubmit={handleSubmit}
      >
        {({values, errors, touched, dirty, handleBlur, handleSubmit, setFieldValue}) => (
          <Form onSubmit={handleSubmit} autoComplete="off" layout="vertical">
            <div style={{position: 'relative', padding: '20px 20px 0 20px'}}>
              {!!user.archived && (
                <div className="view-only-overlay">
                  <span className="view-only-overlay__text">
                    <Icon type="eye" /> View only
                  </span>
                </div>
              )}
              <Form.Item
                label={
                  <span>
                    <span>Email:</span>{' '}
                    <Tooltip title="Please enter your correct LinkedIn login email.">
                      <Icon type="info-circle" className="tooltip-icon" style={{marginRight: 5}} />
                    </Tooltip>
                  </span>
                }
              >
                <Input
                  value={values.email}
                  name="email"
                  className={errors.email && touched.email ? 'invalid-input' : ''}
                  autoComplete="client-user-email"
                  onChange={e => handleChangeEmail(e.target.value, setFieldValue)}
                  onBlur={handleBlur}
                  placeholder="Type your user linkedin email used for logging in on LinkedIn"
                />
              </Form.Item>
              <ErrorMessage name="email" className="error-message" component="div" />

              {client.linkedin_basic_feature && (
                <SwitchRow
                  label={<span>Sales Navigator subscription:</span>}
                  onChange={(checked: boolean) => setFieldValue('basic_linkedin_sub', !checked)}
                  checked={!values.basic_linkedin_sub}
                  isLock={false}
                />
              )}
              {values.basic_linkedin_sub && (
                <Alert
                  style={{
                    marginBottom: 10,
                  }}
                  message={STRINGS.warning.basic_linkedin_user_warning}
                  type="warning"
                />
              )}

              {!user.id && (
                <SwitchRow
                  checked={values.create_team_member}
                  label={
                    <span>
                      Send invite to access {brandConfig.name}:{' '}
                      <InfoIcon filled={false} className="tooltip-icon" message={STRINGS.tooltip.create_team_member} />
                    </span>
                  }
                  onChange={(checked: boolean) => setFieldValue('create_team_member', checked)}
                  isLock={isLockCreateTeamMember(values.email)}
                />
              )}
            </div>

            <div style={{padding: '0 20px 20px 20px'}}>
              {isFirstUserForClient && (
                <Alert
                  className="user-requirements__alert"
                  message={
                    <span>
                      {`Please read `}
                      <b>
                        {isMobile ? (
                          <UserRequirementsPopover>
                            <Button type="link" className="user-requirements__button-link">
                              LinkedIn User Recommendations
                            </Button>
                          </UserRequirementsPopover>
                        ) : (
                          <UserRequirementsTooltip>
                            <Button type="link" className="user-requirements__button-link">
                              LinkedIn User Recommendations
                            </Button>
                          </UserRequirementsTooltip>
                        )}
                      </b>
                    </span>
                  }
                  type="info"
                  style={{marginBottom: 20}}
                />
              )}
              {error && (
                <Row style={{marginBottom: '10px'}}>
                  <Alert message={error} type="error" />
                </Row>
              )}
              <Row className="user-edit-modal__buttons-row" type="flex">
                <Button onClick={dirty ? onClose : onCloseModals}>Cancel</Button>
                <Button
                  className="user-edit-modal__submit-button"
                  loading={loading}
                  disabled={!!user.archived}
                  type="primary"
                  htmlType="submit"
                >
                  Save and Validate
                </Button>
              </Row>
              {!brandConfig.isAffiliate && (
                <Row className="user-edit-modal__terms-row">
                  <span>By clicking save you agree to our</span>{' '}
                  <a href={`https://growth-x.com/terms`} target="_blank" rel="noreferrer noopener">
                    Terms of Use
                  </a>{' '}
                  <span>and</span>{' '}
                  <a href={`https://growth-x.com/privacy`} target="_blank" rel="noreferrer noopener">
                    Privacy Policy
                  </a>
                </Row>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
