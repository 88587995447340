import {message} from 'antd';
import _ from 'lodash';
import React from 'react';

import {User} from '@growth-x/types';
import {brandConfig, STRINGS} from '@growth-x/ui';

import {ProxyService, TaskService, UsersService, AdminMaintenanceService} from '../../services';
import {campaignsActions} from '../campaigns';
import {clientActions} from '../client';
import {WS_USER_ARCHIVED, WS_USER_UNARCHIVED} from '../websocket';

export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';
export const USER_STATS_REQUEST = 'USER_STATS_REQUEST';
export const USER_STATS_SUCCESS = 'USER_STATS_SUCCESS';
export const USER_STATS_FAILURE = 'USER_STATS_FAILURE';
export const CREATE_USER_CLEANUP = 'CREATE_USER_CLEANUP';
export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const VALIDATE_USER_SUCCESS = 'VALIDATE_USER_SUCCESS';
export const VALIDATE_USER_FAILURE = 'VALIDATE_USER_FAILURE';
export const OPEN_USER_SUCCESS = 'OPEN_USER_SUCCESS';
export const OPEN_USER_FAILURE = 'OPEN_USER_FAILURE';
export const VALIDATE_USER_UPDATE_CODE_SUCCESS = 'VALIDATE_USER_UPDATE_CODE_SUCCESS';
export const VALIDATE_USER_UPDATE_CODE_FAILURE = 'VALIDATE_USER_UPDATE_CODE_FAILURE';
export const UPDATE_USER_TASK_FAILURE = 'UPDATE_USER_TASK_FAILURE';
export const UPDATE_USER_TASK_SUCCESS = 'UPDATE_USER_TASK_SUCCESS';
export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS';
export const UPDATE_CUSTOM_PROXY_VISIBLE = 'UPDATE_CUSTOM_PROXY_VISIBLE';

export const usersActions = {
  getUsers,
  cleanupUser,
  createUser,
  updateUser,
  getUsersStats,
  userUpdatedWS,
  userCreatedWS,
  archiveUser,
  unarchiveUser,
  archiveUserAdminMode,
  replaceUser,
  userArchivedWS,
  userUnarchivedWS,
  validateUser,
  openUser,
  validateUserUpdateTask,
  clearUserError,
  clearUserWarning,
  updateSnSubsctiptionSelect,
  clearCookies,
  updateLoginErrorDate,
  updateUserTask,
  revealPassword,
  changeUserState,
  snoozeLoginError,
  changeClientEmail,
  changeClientName,
  changeManagerData,
  validatePassword,
  createUserProxy,
  createUserCustomProxy,
  removeUserCustomProxy,
  updateUserDetails,
  updateCustomProxyVisible,
  replaceUserProxy,
  scheduleArchive,
  cancelScheduledArchive,
};

function archiveUser(userId: number) {
  return (dispatch: any, getState: any) => {
    UsersService.archiveUser(userId)
      .then(({data}: any) => {
        if (data.error === 'non_renewing_subscription') {
          message.info('Your subscription has been canceled, you cannot archive users');
        } else {
          const clientId = getState().client.data.id;
          dispatch(getUsers(clientId)).then(() => dispatch(campaignsActions.getCampaigns(clientId)));
        }
      })
      .catch(() => {
        message.error('Bad request. Please, try again later.');
      });
  };
}

function unarchiveUser(userId: number) {
  return (dispatch: any, getState: any) => {
    UsersService.unarchiveUser(userId)
      .then(() => {
        const clientId = getState().client.data.id;
        dispatch(getUsers(clientId)).then(() => dispatch(campaignsActions.getCampaigns(clientId)));
      })
      .catch(() => {
        message.error('Bad request. Please, try again later.');
      });
  };
}

function archiveUserAdminMode(userId: number, allowOtherUsersToContact: boolean, changeChargebeeUsers: boolean) {
  return (dispatch: any, getState: any) => {
    return AdminMaintenanceService.archiveUser(userId, allowOtherUsersToContact, changeChargebeeUsers)
      .then(() => {
        const clientId = getState().client.data.id;
        dispatch(getUsers(clientId)).then(() => dispatch(campaignsActions.getCampaigns(clientId)));
      })
      .catch(() => {
        message.error('Bad request. Please, try again later.');
      });
  };
}

function revealPassword(userId: number) {
  return () => {
    UsersService.revealPassword(userId)
      .then(({data}) => {
        navigator.clipboard.writeText(data.password);
        message.success('Password copied to clipboard');
      })
      .catch(() => {
        message.error('Bad request. Please, try again later.');
      });
  };
}

function changeUserState(userId: number, value: boolean) {
  return () => {
    UsersService.changeUserState(userId, value)
      .then(() => {
        message.success('State changed successfully');
      })
      .catch(() => {
        message.error('Bad request. Please, try again later.');
      });
  };
}

function changeClientEmail(newEmail: string) {
  return () => {
    UsersService.changeClientEmail(newEmail)
      .then(() => {
        message.success('Email changed successfully');
      })
      .catch(err => {
        if (err.response?.data?.[0].message) {
          message.warning(err.response.data[0].message);
        } else {
          message.error('Bad request. Please, try again later.');
        }
      });
  };
}

function changeClientName(newName: string) {
  return () => {
    UsersService.changeClientName(newName)
      .then(() => {
        message.success('Name changed successfully');
      })
      .catch(err => {
        if (err.response?.data?.[0].message) {
          message.warning(err.response.data[0].message);
        } else {
          message.error('Bad request. Please, try again later.');
        }
      });
  };
}

function changeManagerData(values) {
  return dispatch => {
    UsersService.changeManagerData(values)
      .then(() => {
        message.success('Csm data changed successfully');
        dispatch(clientActions.getClient());
      })
      .catch(err => {
        if (err.response?.data?.[0].message) {
          message.warning(err.response.data[0].message);
        } else {
          message.error('Bad request. Please, try again later.');
        }
      });
  };
}

function snoozeLoginError(userId: number, days: number) {
  return () => {
    UsersService.snoozeLoginError(userId, days)
      .then(() => {
        message.success('Login error pulse snoozed successfully.');
      })
      .catch(() => {
        message.error('Bad request. Please, try again later.');
      });
  };
}

function getUsers(clientId: number | string) {
  return (dispatch: any) => {
    dispatch(request());
    return UsersService.getUsersByClient(clientId).then(
      ({data}: any) => {
        const users = data.users.map(addUserStats);
        dispatch(success(users));
      },
      (error: string) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {type: GET_USERS_REQUEST};
  }
  function success(users: any) {
    return {type: GET_USERS_SUCCESS, users};
  }
  function failure(error: string) {
    return {type: GET_USERS_FAILURE, error};
  }
}

function cleanupUser() {
  return (dispatch: any) => {
    dispatch(cleanup());
  };

  function cleanup() {
    return {type: CREATE_USER_CLEANUP};
  }
}

function createUser(user: User) {
  return (dispatch: any) => {
    dispatch(request(user));

    return UsersService.create(user).then(
      ({data}: any) => {
        if (data.unarchived_user) {
          message.info(`We found an existing user with email ${user.email}. User has been unarchived`, 5);
        }
        if (data.users.length) {
          message.success(
            <span id={data.users.length === 1 ? 'first_user_created' : ''}>
              User {user.first_name} {user.last_name} has been created
            </span>,
            5
          );
          dispatch(success(addUserStats(data.users[0])));
          return data.users[0];
        }
      },
      (error: any) => {
        const errorCode = error.response && error.response.data ? error.response.data.error : '';
        let errorMessage = STRINGS.error_server_response;
        if (errorCode === 'email_already_exists') {
          errorMessage = 'This user is already active on your account';
        } else if (errorCode === 'active_user_exists_of_other_client') {
          errorMessage = `This user is already active on another ${brandConfig.name} account, please contact ${brandConfig.contact} for help`;
        } else if (errorCode === 'archived_user_exists_of_other_client') {
          errorMessage = `The user you’re trying to add was active on another ${brandConfig.name} account, please contact ${brandConfig.contact} for help`;
        } else if (errorCode === 'client_exists_with_email') {
          errorMessage = `This user already exists on another ${brandConfig.name} account, please contact ${brandConfig.contact} for help`;
        } else if (errorCode === 'team_member_email_already_exists') {
          errorMessage =
            "This user already exists as a team member, please remove it from team members or disable 'Send invite to access Growth-X'";
        }
        message.error(errorMessage);
        dispatch(failure(errorMessage));
        return {};
      }
    );
  };

  function request(user: User) {
    return {type: CREATE_USER_REQUEST, user};
  }
  function success(user: User) {
    return {type: CREATE_USER_SUCCESS, user};
  }
  function failure(error: string) {
    return {type: CREATE_USER_FAILURE, error};
  }
}

function replaceUser(user: User, replaceUserId: number) {
  return (dispatch: any, getState: any) => {
    dispatch(request(user));

    return UsersService.create({
      ...user,
      method: 'replace_user',
      replace_user_id: replaceUserId,
    }).then(
      ({data}: any) => {
        if (data.unarchived_user) {
          message.info(`We found an existing user with email ${user.email}. User has been unarchived`, 5);
        }
        if (data.users.length) {
          const clientId = getState().client.data.id;
          message.success(`User ${user.first_name} ${user.last_name} has been replaced`, 5);
          dispatch(getUsers(clientId));
          return data.users[0];
        }
      },
      (error: any) => {
        const errorCode = error.response && error.response.data ? error.response.data.error : '';
        let errorMessage = STRINGS.error_server_response;
        if (errorCode === 'email_already_exists') {
          errorMessage = 'This user is already active on your account';
        } else if (errorCode === 'active_user_exists_of_other_client') {
          errorMessage = `This user is already active on another ${brandConfig.name} account, please contact ${brandConfig.contact} for help`;
        } else if (errorCode === 'archived_user_exists_of_other_client') {
          errorMessage = `The user you’re trying to add was active on another ${brandConfig.name} account, please contact ${brandConfig.contact} for help`;
        } else if (errorCode === 'client_exists_with_email') {
          errorMessage = `This user already active on another ${brandConfig.name} account, please contact ${brandConfig.contact} for help`;
        } else if (errorCode === 'team_member_email_already_exists') {
          errorMessage =
            "This user already exists as a team member, please remove it from team members or disable 'Send invite to access Growth-X'";
        }
        message.error(errorMessage);
        dispatch(failure(errorMessage));
        return {};
      }
    );
  };

  function request(user: User) {
    return {type: CREATE_USER_REQUEST, user};
  }
  function failure(error: string) {
    return {type: CREATE_USER_FAILURE, error};
  }
}

function updateUser(user: User) {
  return (dispatch: any) => {
    dispatch(request(user));
    return UsersService.update(user).then(
      ({data}: any) => {
        if (data.users.length) {
          message.success(`User ${user.first_name} ${user.last_name} has been updated`, 5);
          dispatch(success(addUserStats(data.users[0])));
          return data.users[0];
        }
      },
      (error: any) => {
        const errorCode = error.response && error.response.data ? error.response.data.error : '';
        let errorMessage = error;
        if (errorCode && errorCode === 'email_already_exists') {
          errorMessage = 'User with this email is already existing.';
        }
        dispatch(failure(errorMessage));
        return null;
      }
    );
  };

  function request(user: User) {
    return {type: UPDATE_USER_REQUEST, user};
  }
  function success(user: User) {
    return {type: UPDATE_USER_SUCCESS, user};
  }
  function failure(error: string) {
    return {type: UPDATE_USER_FAILURE, error};
  }
}

function getUsersStats(days: number) {
  return (dispatch: any) => {
    dispatch(request());

    UsersService.loadUserStats(days).then(
      ({data}: any) => {
        dispatch(success(transformStats(data.stats)));
      },
      (error: string) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {type: USER_STATS_REQUEST};
  }
  function success(stats: any) {
    return {type: USER_STATS_SUCCESS, stats};
  }
  function failure(error: string) {
    return {type: USER_STATS_FAILURE, error};
  }
  function transformStats(stats: any[]) {
    const result: any[] = [];
    stats.forEach(elem => {
      result.push({day: elem.day, color: '#ff0', name: 'invites', value: elem.invites || 0});
      result.push({day: elem.day, color: '#0ff', name: 'followups', value: elem.followups || 0});
      result.push({day: elem.day, color: '#0f0', name: 'responses', value: elem.responses || 0});
      result.push({day: elem.day, color: '#0f0', name: 'inmails', value: elem.inmails || 0});
    });
    return result;
  }
}

function userUpdatedWS(users: User[]) {
  return async (dispatch: any) => {
    const updatedUsers = users.map(addUserStats);
    updatedUsers.forEach(user => {
      dispatch({type: UPDATE_USER_SUCCESS, user});
    });
  };
}

function userArchivedWS(users: User[]) {
  return async (dispatch: any) => {
    users.forEach(user => {
      dispatch({type: WS_USER_ARCHIVED, user});
    });
  };
}

function userUnarchivedWS(users: User[]) {
  return async (dispatch: any) => {
    const updatedUsers = users.map(addUserStats);
    updatedUsers.forEach(user => {
      dispatch({type: WS_USER_UNARCHIVED, user});
    });
  };
}

function userCreatedWS(user: User) {
  return async (dispatch: any) => {
    const createdUserWithStats = addUserStats(user);
    dispatch({type: CREATE_USER_SUCCESS, user: createdUserWithStats});
  };
}

function addUserStats(u: User): User {
  const user = {...u};
  if (!user.stats) {
    return user;
  }
  user.chartData = [];
  user.chartEmpty = true;
  user.chartLabels = _.map(user.stats, function (item: any) {
    return item['day'];
  });
  user.chartData.push(
    _.map(user.stats, function (item: any) {
      return item['invites'];
    })
  );
  user.chartData.push(
    _.map(user.stats, function (item: any) {
      return item['followups'];
    })
  );
  user.chartData.push(
    _.map(user.stats, function (item: any) {
      return item['responses'];
    })
  );
  for (const dataset of user.chartData) {
    if (
      _.findIndex(dataset, function (item: any) {
        return item > 0;
      }) > -1
    ) {
      user.chartEmpty = false;
      break;
    }
  }
  return user;
}

function validateUser(params) {
  return (dispatch: any) => {
    return TaskService.postTask({
      ...params,
      code: 'validation',
      status: 'created',
    }).then(
      (result: any) => {
        dispatch(success(result.data.app_task));
        return true;
      },
      () => {
        dispatch(failure());
        return false;
      }
    );
  };
  function failure() {
    return {type: VALIDATE_USER_FAILURE};
  }
  function success(payload: any) {
    return {type: VALIDATE_USER_SUCCESS, payload};
  }
}

function openUser(params) {
  return (dispatch: any) => {
    return TaskService.postTask({
      ...params,
      code: 'open_feature',
      status: 'created',
    }).then(
      (result: any) => {
        dispatch(success(result.data.app_task));
        return true;
      },
      () => {
        dispatch(failure());
        return false;
      }
    );
  };
  function failure() {
    message.error('Not able to open server now. Please try again later.');
    return {type: OPEN_USER_FAILURE};
  }
  function success(payload: any) {
    return {type: OPEN_USER_SUCCESS, payload};
  }
}

function validateUserUpdateTask(params) {
  return (dispatch: any) => {
    TaskService.updateTask(params).then(
      (result: any) => {
        dispatch(success(result.data.app_task));
      },
      () => dispatch(failure())
    );
  };
  function failure() {
    return {type: VALIDATE_USER_UPDATE_CODE_FAILURE};
  }
  function success(payload: any) {
    return {type: VALIDATE_USER_UPDATE_CODE_SUCCESS, payload};
  }
}

function clearUserError(user: User) {
  return async (dispatch: any) => {
    user.error_code = '';
    await UsersService.updateError(user).then(
      ({data}: any) => {
        if (data.users.length) {
          dispatch(success(data.users[0]));
        }
      },
      () => dispatch(failure())
    );
  };

  function failure() {
    return {type: UPDATE_USER_FAILURE};
  }
  function success(user: User) {
    return {type: UPDATE_USER_SUCCESS, user};
  }
}

function clearUserWarning(user: User) {
  return async (dispatch: any) => {
    user.warning_code = '';
    await UsersService.updateWarning(user).then(
      ({data}: any) => {
        if (data.users.length) {
          dispatch(success(data.users[0]));
        }
      },
      () => dispatch(failure())
    );
  };

  function failure() {
    return {type: UPDATE_USER_FAILURE};
  }
  function success(user: User) {
    return {type: UPDATE_USER_SUCCESS, user};
  }
}

function clearCookies(userId: number) {
  return async (dispatch: any) => {
    await UsersService.updateCookies(userId, null).then(
      ({data}: any) => {
        if (data.users.length) {
          const user = data.users[0];
          message.success(`User ${user.first_name} ${user.last_name} has been updated`, 5);
          dispatch(success(user));
        }
      },
      () => dispatch(failure())
    );
  };

  function failure() {
    return {type: UPDATE_USER_FAILURE};
  }
  function success(user: User) {
    return {type: UPDATE_USER_SUCCESS, user};
  }
}

function updateLoginErrorDate(userId: number) {
  return async (dispatch: any) => {
    await UsersService.updateLoginErrorDate(userId, {clear_date: true}).then(
      ({data}: any) => {
        if (data.users.length) {
          const user = data.users[0];
          message.success(`User ${user.first_name} ${user.last_name} has been updated`, 5);
          dispatch(success(user));
        }
      },
      () => dispatch(failure())
    );
  };

  function failure() {
    return {type: UPDATE_USER_FAILURE};
  }
  function success(user: User) {
    return {type: UPDATE_USER_SUCCESS, user};
  }
}

function updateSnSubsctiptionSelect(userId: number, value: number) {
  return async () => {
    return UsersService.updateSnSubscriptionSelect(userId, value);
  };
}

function updateUserTask(params) {
  return (dispatch: any) => {
    TaskService.updateTask(params).then(
      (result: any) => {
        dispatch(success(result.data.app_task));
      },
      () => dispatch(failure())
    );
  };
  function failure() {
    message.warning('Not able to update task.');
    return {type: UPDATE_USER_TASK_FAILURE};
  }
  function success(payload: any) {
    return {type: UPDATE_USER_TASK_SUCCESS, payload};
  }
}

function validatePassword(userId: number, password: string) {
  return async () => {
    return UsersService.validatePassword(userId, password)
      .then(() => {
        return true;
      })
      .catch(err => {
        const errorMessage =
          err?.response?.data?.non_field_errors?.[0]?.message || 'Bad request. Please, try again later.';
        console.log(JSON.stringify(err?.response?.data));
        message.error(errorMessage);
        return false;
      });
  };
}

function createUserProxy(userId, location: string) {
  return (dispatch, getState) => {
    const [loc_country, loc_state, loc_city] = location.split('/');
    return ProxyService.createUserProxy({user: userId, loc_country, loc_state, loc_city})
      .then(() => {
        message.success('User proxy created');
        const clientId = getState().client.data.id;
        dispatch(getUsers(clientId));
        return true;
      })
      .catch(error => {
        let errorMessage = error?.response?.data?.non_field_errors
          ? error?.response?.data?.non_field_errors?.map(m => m.message)
          : error?.response?.data?.map(m => m.message);
        if (errorMessage?.length) {
          errorMessage = errorMessage.join(' ');
        } else {
          errorMessage = 'Something went wrong with proxy creation.';
        }
        message.error(errorMessage);
        return false;
      });
  };
}

function createUserCustomProxy(params: any) {
  return (dispatch, getState) => {
    return ProxyService.addUserCustomProxy(params)
      .then(result => {
        message.success('Custom user proxy created');
        const clientId = getState().client.data.id;
        dispatch(getUsers(clientId));
        return result;
      })
      .catch(error => {
        const {ip, port, username, password} = error?.response?.data || {};
        let errorMessage = 'Custom proxy could not created!';
        if (ip && ip.length > 0) {
          errorMessage = ip[0].message;
        } else if (port && port.length > 0) {
          errorMessage = port[0].message;
        } else if (username && username.length > 0) {
          errorMessage = username[0].message;
        } else if (password && password.length > 0) {
          errorMessage = password[0].message;
        }
        message.error(errorMessage);
        return false;
      });
  };
}

function removeUserCustomProxy(params: any) {
  return (dispatch, getState) => {
    return ProxyService.clearUserCustomProxy(params)
      .then(result => {
        message.success('Custom user proxy removed');
        const clientId = getState().client.data.id;
        dispatch(updateUserDetails({...getState().users.userDetails, proxy: null}));
        dispatch(getUsers(clientId));
        return result;
      })
      .catch(error => {
        console.log('errorMessage: ', error);
        message.error('User custom proxy could not removed!');
      });
  };
}

function updateUserDetails(userDetails) {
  return async (dispatch: any) => {
    dispatch({type: UPDATE_USER_DETAILS, userDetails});
  };
}

function updateCustomProxyVisible(customProxyVisible) {
  return async (dispatch: any) => {
    dispatch({type: UPDATE_CUSTOM_PROXY_VISIBLE, customProxyVisible});
  };
}

function replaceUserProxy(user) {
  return (dispatch, getState) => {
    return ProxyService.replaceUserProxy({user})
      .then(result => {
        dispatch(updateUserDetails({...getState().users.userDetails, proxy: result.data}));
        message.success('User proxy replaced ');
        return result;
      })
      .catch(() => {
        message.error('Something went wrong with proxy replacement');
      });
  };
}

function scheduleArchive(userId: number, date: string) {
  return (dispatch: any, getState: any) => {
    UsersService.scheduleArchive(userId, date)
      .then(() => {
        const clientId = getState().client.data.id;
        dispatch(getUsers(clientId));
        message.success('Archivation was successfully scheduled');
      })
      .catch(() => {
        message.error('Bad request. Please, try again later.');
      });
  };
}

function cancelScheduledArchive(userId: number) {
  return (dispatch: any, getState: any) => {
    UsersService.cancelScheduledArchive(userId)
      .then(() => {
        const clientId = getState().client.data.id;
        dispatch(getUsers(clientId));
        message.success('Scheduled archivation was successfully canceled');
      })
      .catch(() => {
        message.error('Bad request. Please, try again later.');
      });
  };
}
