import {Badge, Icon, Layout, Menu} from 'antd';
import React, {useMemo} from 'react';
import {Link} from 'react-router-dom';

import {findIconDefinition, IconLookup} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {CONSTANTS, isClientTrial, IconWrapper} from '@growth-x/ui';

import './navigation.scss';
import {InboxNavigation as getInboxNavigation} from './inboxNavigation';

const {Sider} = Layout;

export const Navigation = ({
  location,
  isNavCollapsed,
  setSettings,
  client,
  users,
  unreadCountByUser,
  inboxLoading,
  isAdmin,
  openKeys,
}: any) => {
  const pages = getPages(client, isAdmin);
  const filteredPathname = `/${location.pathname.split('/')[1]}`;
  // add selected inbox user
  const selectedInboxUser = location.pathname.split('/').pop();
  const selectedKeys = useMemo(() => {
    const array = pages.reduce(
      (acc, item) => (item.path === filteredPathname ? acc.concat(item.key) : acc),
      [] as string[]
    );
    if (array[0] === CONSTANTS.inbox_page_key) {
      array.push(selectedInboxUser);
    }
    return array;
  }, [pages, filteredPathname, selectedInboxUser]);

  const unreadCountForAllUser = useMemo(() => {
    return (users || []).filter(x => !x.archived && x.error_code).length;
  }, [users]);

  const onOpenChange = (keys: any) => {
    if (isNavCollapsed) {
      if (keys.length > 0) {
        setSettings({openKeys: keys});
      } else {
        setSettings({openKeys: []});
      }
    }
  };

  return (
    <Sider
      theme="light"
      className="navigation"
      collapsible
      collapsed={isNavCollapsed}
      onCollapse={() => {
        setSettings({isNavCollapsed: !isNavCollapsed, openKeys: !isNavCollapsed ? [] : [CONSTANTS.inbox_page_key]});
      }}
    >
      <Menu
        className="menu-tabs"
        mode="inline"
        theme="light"
        onOpenChange={onOpenChange}
        openKeys={openKeys}
        defaultOpenKeys={openKeys}
        selectedKeys={selectedKeys}
      >
        {pages.map(page =>
          page.label === 'Inbox' ? (
            getInboxNavigation({
              page,
              client,
              users,
              unreadCountByUser,
              inboxLoading,
              selectedInboxUser,
              openKeys,
              setSettings,
            })
          ) : (
            <Menu.Item key={page.key}>
              <Link to={page.path}>
                {page.icon.prefix ? (
                  <IconWrapper>
                    <FontAwesomeIcon icon={findIconDefinition(page.icon as IconLookup)} />
                  </IconWrapper>
                ) : (
                  <Icon type={page.icon.iconName} />
                )}
                <span>
                  {page.label}{' '}
                  {page.label === 'Users' && <Badge className="users-error-count" count={unreadCountForAllUser} />}
                </span>
              </Link>
            </Menu.Item>
          )
        )}
      </Menu>
    </Sider>
  );
};

function getPages(client: any, isAdmin: boolean) {
  const excludeList: any = [];
  const isTrialSubscription = isClientTrial(client.type);
  if (isTrialSubscription) {
    excludeList.push('Prospects');
  }
  if (!client.inbox_feature) {
    excludeList.push('Inbox');
  }
  if (!isAdmin) {
    excludeList.push('Audience');
    excludeList.push('Tasks');
  }
  return pageList.filter((page: any) => !excludeList.find((excludeLabel: any) => excludeLabel === page.label));
}

const pageList = [
  {label: 'Campaigns', path: '/', icon: {prefix: 'fas', iconName: 'list-ul'}, key: '1'},
  {label: 'Users', path: '/users', icon: {prefix: 'fas', iconName: 'users'}, key: '2'},
  {label: 'Prospects', path: '/prospects', icon: {prefix: 'fas', iconName: 'user-clock'}, key: '3'},
  {label: 'Connections', path: '/connections', icon: {prefix: 'fas', iconName: 'project-diagram'}, key: '4'},
  {label: 'Inbox', path: '/inbox', icon: {prefix: 'fas', iconName: 'inbox'}, key: '5'},
  {label: 'Templates', path: '/templates', icon: {prefix: 'fas', iconName: 'file-alt'}, key: '6'},
  {label: 'Audience', path: '/audience', icon: {prefix: 'fas', iconName: 'user-tie'}, key: '7'},
  {label: 'Tasks', path: '/tasks', icon: {prefix: 'fas', iconName: 'tasks'}, key: '8'},
];
