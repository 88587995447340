import {Select, Button} from 'antd';
import React from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconWrapper} from '@growth-x/ui';

const {Option} = Select;

const CAMPAIGN_STATUSES = [
  {key: 'all', value: 'All'},
  {key: 'unarchived_only', value: 'Active'},
  {key: 'archived_only', value: 'Archived'},
];

export const ArchiveStateFilter = ({handleChange, archiveMode, clearAndClose}) => {
  return (
    <div className="campaigns-page__filter-wrapper">
      <IconWrapper>
        <FontAwesomeIcon icon={['fas', 'archive']} />
      </IconWrapper>
      <Select
        maxTagCount={1}
        onChange={handleChange}
        defaultValue={archiveMode}
        className="campaigns-page__filter-input"
      >
        {CAMPAIGN_STATUSES.map(s => (
          <Option key={s.key}>{s.value}</Option>
        ))}
      </Select>
      <Button onClick={clearAndClose} icon="close" type="link" className="campaigns-page__close-filter" size="small" />
    </div>
  );
};
