import {SignupRequestParams} from "@growth-x/types";

export class ClientService {
  client: any;
  constructor({client}: any) {
    this.client = client;
  }
  handleResponse(response: any) {
    const {data} = response;
    if (response.status !== 200) {
      if (response.status === 401) {
        return this.logout();
      }
      return Promise.reject('Bad request');
    }
    return data;
  }
  login(email: string, password: string) {
    return this.client
      .post('clients/login', {email, password, source: 'webapp'})
      .then(this.handleResponse)
      .then(({clients, status, team_member}: any) => {
        const client = clients[0];
        if (!client) {
          return Promise.reject(status);
        }
        return {client, team_member};
      });
  }
  logout() {
    return this.client.post('clients/logout', {});
  }
  signup(params: SignupRequestParams) {
    return this.client
      .post('clients/signup', {...params, source: 'webapp'})
      .then(this.handleResponse)
      .then(({clients, status, team_member}: any) => {
        const client = clients[0];
        if (!client) {
          return Promise.reject(status);
        }
        return {client, team_member};
      });
  }
  updateAttribute(attributes: any) {
    return this.client
      .put('clients', {...attributes})
      .then(this.handleResponse)
      .then(({clients, status}: any) => {
        const client = clients[0];
        if (!client) {
          return Promise.reject(status);
        }
        return client;
      });
  }
  requestCampaignsInitialReview() {
    return this.client.put('clients', {method: 'submit_for_initial_review'});
  }
  getClient() {
    return this.client
      .get('clients', {})
      .then(this.handleResponse)
      .then(({clients, status, team_member}: any) => {
        const client = clients[0];
        if (!client) {
          return Promise.reject(status);
        }
        return {client, team_member};
      });
  }

  manageSubscription(subscriptionStatus: boolean, users: any, addons: any) {
    return this.client.put('clients', {
      method: 'manage_subscription',
      subscription_toggle_on: subscriptionStatus,
      users: users,
      addons: addons,
    });
  }

  requestPasswordReset(email: string) {
    return this.client.post('clients/password_reset/generate', {email: email});
  }
  checkPasswordResetToken(token: string) {
    return this.client.post('clients/password_reset/check', {token: token});
  }
  changePassword(token: string, password: string) {
    return this.client.put('clients/password_reset/complete', {token: token, new_password: password});
  }
  updatePassword(old_password: string, new_password: string) {
    return this.client.put('api/v2/clients/change_password', {new_password, old_password});
  }
  changeClientFeatures(features: any) {
    return this.client.post('admin_maintenance/change_client_features', {...features});
  }
  notifyInstance(params: any) {
    return this.client.post('api/v2/clients/notify_instance', params);
  }
}
