import {Alert, Icon, Layout, Modal} from 'antd';
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {
  AccountPassword,
  AccountSwitcher,
  checkCloudOffline,
  Hubspot,
  InfoIcon,
  LazySalesforce,
  LazyShowCSM,
  LazyShowCSMAdmin,
  LazySubscriptionDetails,
  LazyZapier,
  LazyNotificationSettings,
  STRINGS,
  brandConfig,
  LazyRb2b,
  TrialFinishedModal,
  CONSTANTS,
} from '@growth-x/ui';

import {
  AccountsSettingsContainer,
  InstantlyContainer,
  MembersDetailsContainer,
  TemplatesModalContainer,
  AdminToolsContainer,
  BillingDetailsContainer,
  LogoContainer,
} from '../../containers';
import {adminMaintenanceActions} from '../../redux/adminMaintenance';
import {chargebeeActions} from '../../redux/chargebee';
import {clientActions} from '../../redux/client';
import {headerModalActions} from '../../redux/headerModal';
import {hsOauth2Actions} from '../../redux/hsOauth2';
import {notificationsActions} from '../../redux/notifications';
import {sfOauth2Actions} from '../../redux/sfOauth2';
import {usersActions} from '../../redux/users';

import './header.scss';

const {Header} = Layout;

const getTitle = key => {
  switch (key) {
    case 'setting':
      return 'Settings';
    case 'password':
      return 'Change password';
    case 'subscription':
      return 'Manage Subscription';
    case 'notificationSettings':
      return 'Manage Notifications';
    case 'members':
      return 'Manage Members';
    case 'salesforce':
      return 'Authorize Salesforce';
    case 'hubspot':
      return 'Authorize Hubspot';
    case 'instantly':
      return 'Instantly Settings';
    case 'zapier':
      return 'Use Zapier';
    case 'profile':
      return 'Profile';
    case 'admin_tools':
      return 'Admin Tools';
    case 'rb2b':
      return 'Authorize RB2B';
    default:
      return '';
  }
};

const DesktopHeader = ({
  client,
  users,
  team_member,
  logout,
  chargebeeInstance,
  notifications,
  toggleNotifications,
  manageSubscription,
  settings,
  updatePassword,
  loadingUpdate,
  errorUpdate,
  getSfAuthorizedCredentials,
  clearSfAuthorizedCredentials,
  setSfAuthorizationCode,
  getHsAuthorizedCredentials,
  clearHsAuthorizedCredentials,
  setHsAuthorizationCode,
  waitSfLogoutRequest,
  waitSfLogoutSuccess,
  getPortalSession,
  sfOauth2,
  hsOauth2,
  changeClientEmail,
  changeClientName,
  changeManagerData,
  markNotificationAsRead,
  shouldLoadMoreNotifications,
  loadMoreNotifications,
  getNotificationSettings,
  setNotificationSettings,
  getHubspotUsers,
  getHubspotSearchProperty,
  configureHubspot,
  headerModal,
  setHeaderModal,
  activateRegularPlan,
  rb2bLoading,
  rb2bLatestEvent,
}: any) => {
  const [trialBannerClosed, setTrialBannerClosed] = useState(false);
  const isCloudOffline = checkCloudOffline(client?.data?.last_activity_date, client?.serverState);
  const isServerSleeping = client?.serverState === 'stopped';
  const history = useHistory();

  useEffect(() => {
    const trialBannerClosed = sessionStorage.getItem(CONSTANTS.trial_ended_banner_closed);
    setTrialBannerClosed(!!trialBannerClosed);
    if (history.location.search.includes('billing-details')) {
      openBillingModal();
    }
    if (history.location.search.includes('subscription-activation')) {
      if (brandConfig.isLeadoku) {
        activateRegularPlan();
      }
      history.push('/');
    }
  }, []);
  const hideTrialBanner = () => {
    sessionStorage.setItem(CONSTANTS.trial_ended_banner_closed, 'true');
    setTrialBannerClosed(true);
  };
  // const onUpgrade = () => IntercomService.trackIntercomEvent('upgrade_request', true, !client.upgrade_request);
  const openBillingModal = () => {
    if (client.data.subscription_account_id && client.data.new_billing_feature) {
      setHeaderModal('subscription');
    }
    history.push('/');
  };
  const onClick = async ({key}) => {
    if (key === 'logout') {
      logout();
    } else if (key === 'about-us') {
      setHeaderModal(undefined);
    } else if (key === 'invoices') {
      const result = await getPortalSession();
      chargebeeInstance.setPortalSession(() => {
        return new Promise(resolve => resolve(result));
      });
      chargebeeInstance
        .createChargebeePortal()
        .openSection({sectionType: window.Chargebee.getPortalSections().BILLING_HISTORY});
    } else if (key === 'payment') {
      const result = await getPortalSession();
      chargebeeInstance.setPortalSession(() => {
        return new Promise(resolve => resolve(result));
      });
      chargebeeInstance
        .createChargebeePortal()
        .openSection({sectionType: window.Chargebee.getPortalSections().PAYMENT_SOURCES});
    } else if (key === 'subscription') {
      setHeaderModal(key);
    } else {
      setHeaderModal(key);
    }
  };
  const getWidth = () => {
    if (headerModal === 'members' || headerModal === 'zapier') {
      return '800px';
    }
    if (headerModal === 'admin_tools') {
      return '1000px';
    }
    return '500px';
  };
  const widthProps = {width: getWidth()};

  return (
    <Header className="header__container">
      <LogoContainer />
      <div className="header__info-container" style={{flexGrow: 1, display: 'flex', justifyContent: 'flex-start'}}>
        {client?.data?.running && !isCloudOffline && (
          <span className="header__running">
            <Icon type="sync" spin style={{color: 'var(--color-icon-sync)'}} />
            <span style={{marginRight: '5px'}}>Campaigns are currently running</span>
            <InfoIcon message="Saved changes will take effect within the next 24 hours. For changes to take effect immediately, please disable campaigns before editing and then re-enable campaigns." />
          </span>
        )}
        {!!client?.data?.enabled && isCloudOffline && (settings.isAdmin || client.data?.affiliate?.length > 0) && (
          <Alert className="header__could-offline" message={STRINGS.error.cloud_offline} type="warning" showIcon />
        )}
        {!isCloudOffline && settings.isAdmin && isServerSleeping && (
          <Alert className="header__could-offline" message={STRINGS.error.cloud_sleeping} type="warning" showIcon />
        )}
      </div>
      <AccountSwitcher
        client={client}
        team_member={team_member}
        notifications={notifications}
        toggleNotifications={toggleNotifications}
        onClick={onClick}
        isAdmin={settings.isAdmin}
        onReadNotification={markNotificationAsRead}
        loadMoreNotifications={loadMoreNotifications}
        shouldLoadMoreNotifications={shouldLoadMoreNotifications}
      />
      <Modal
        visible={!!headerModal}
        title={getTitle(headerModal)}
        footer={null}
        destroyOnClose={true}
        onCancel={() => setHeaderModal(undefined)}
        style={{top: 20}}
        {...widthProps}
      >
        {headerModal === 'password' && (
          <AccountPassword loading={loadingUpdate} error={errorUpdate} onSubmit={updatePassword} />
        )}
        {headerModal === 'admin_tools' && <AdminToolsContainer />}
        {headerModal === 'profile' &&
          (settings.isAdmin ? (
            <LazyShowCSMAdmin
              client={client.data}
              changeClientEmail={changeClientEmail}
              changeClientName={changeClientName}
              onSubmit={(values: any) => {
                changeManagerData(values);
                setHeaderModal(undefined);
              }}
            />
          ) : (
            <LazyShowCSM client={client.data} />
          ))}
        {headerModal === 'settings' && <AccountsSettingsContainer />}
        {headerModal === 'subscription' && client.data.new_billing_feature && <BillingDetailsContainer />}
        {headerModal === 'subscription' && !client.data.new_billing_feature && (
          <LazySubscriptionDetails
            client={client}
            users={users}
            loading={false}
            error={''}
            editable={false}
            onClose={() => setHeaderModal(undefined)}
            onManageSubscription={manageSubscription}
          />
        )}
        {headerModal === 'members' && <MembersDetailsContainer />}
        {headerModal === 'salesforce' && (
          <LazySalesforce
            oauth2={sfOauth2}
            setAuthorizationCode={setSfAuthorizationCode}
            getAuthorizedCredentials={getSfAuthorizedCredentials}
            clearAuthorizedCredentials={clearSfAuthorizedCredentials}
            waitSfLogoutRequest={waitSfLogoutRequest}
            waitSfLogoutSuccess={waitSfLogoutSuccess}
          />
        )}
        {headerModal === 'rb2b' && (
          <LazyRb2b client={client.data} loading={rb2bLoading} latestEvent={rb2bLatestEvent} />
        )}
        {headerModal === 'hubspot' && (
          <Hubspot
            oauth2={hsOauth2}
            setAuthorizationCode={setHsAuthorizationCode}
            getAuthorizedCredentials={getHsAuthorizedCredentials}
            clearAuthorizedCredentials={clearHsAuthorizedCredentials}
            getUsers={getHubspotUsers}
            getSearchProperty={getHubspotSearchProperty}
            configure={configureHubspot}
          />
        )}
        {headerModal === 'instantly' && <InstantlyContainer />}
        {headerModal === 'zapier' && (
          <LazyZapier app={brandConfig.zapierApp} link={brandConfig.articles.integrateZapier} />
        )}
        {headerModal === 'notificationSettings' && (
          <LazyNotificationSettings
            getNotificationSettings={getNotificationSettings}
            setNotificationSettings={setNotificationSettings}
            notifications={notifications}
            users={users}
            onClose={() => setHeaderModal(undefined)}
            teamMember={team_member}
          />
        )}
      </Modal>
      {!trialBannerClosed && client.data.awaiting_subscription_activation && (
        <TrialFinishedModal client={client.data} onClose={hideTrialBanner} />
      )}
      <TemplatesModalContainer />
    </Header>
  );
};

const mapStateToProps = (state: any) => ({
  client: state.client,
  users: state.users.list,
  team_member: state.client.team_member,
  notifications: state.notifications,
  settings: state.settings,
  loadingUpdate: state.client.loadingUpdate,
  errorUpdate: state.client.errorUpdate,
  sfOauth2: state.sfOauth2,
  hsOauth2: state.hsOauth2,
  chargebeeInstance: state.chargebee.instance,
  unreadNotificationsCount: state.notifications.unreadNotificationsCount,
  shouldLoadMoreNotifications: state.notifications.nextUrl,
  headerModal: state.headerModal.headerModal,
  rb2bLoading: state.rb2b.isLoading,
  rb2bLatestEvent: state.rb2b.event,
});

const actionCreators = {
  logout: clientActions.logout,
  toggleNotifications: notificationsActions.toggleNotifications,
  manageSubscription: clientActions.manageSubscription,
  changeClientEmail: usersActions.changeClientEmail,
  changeClientName: usersActions.changeClientName,
  changeManagerData: usersActions.changeManagerData,
  updatePassword: clientActions.updatePassword,
  getSfAuthorizedCredentials: sfOauth2Actions.getAuthorizedCredentials,
  clearSfAuthorizedCredentials: sfOauth2Actions.clearAuthorizedCredentials,
  setSfAuthorizationCode: sfOauth2Actions.setAuthorizationCode,
  getHsAuthorizedCredentials: hsOauth2Actions.getAuthorizedCredentials,
  clearHsAuthorizedCredentials: hsOauth2Actions.clearAuthorizedCredentials,
  setHsAuthorizationCode: hsOauth2Actions.setAuthorizationCode,
  getHubspotUsers: hsOauth2Actions.getUsers,
  getHubspotSearchProperty: hsOauth2Actions.getSearchProperty,
  configureHubspot: hsOauth2Actions.configureHubspot,
  waitSfLogoutSuccess: sfOauth2Actions.waitSfLogoutSuccess,
  waitSfLogoutRequest: sfOauth2Actions.waitSfLogoutRequest,
  getPortalSession: chargebeeActions.getPortalSession,
  markNotificationAsRead: notificationsActions.markNotificationAsRead,
  loadMoreNotifications: notificationsActions.loadMoreNotifications,
  getNotificationSettings: notificationsActions.getNotificationSettings,
  setNotificationSettings: notificationsActions.setNotificationSettings,
  setProgressDone: adminMaintenanceActions.setProgressDone,
  setHeaderModal: headerModalActions.setHeaderModal,
  activateRegularPlan: chargebeeActions.activateRegularPlan,
};

const ConnectedHeader = connect(mapStateToProps, actionCreators)(DesktopHeader);

export {ConnectedHeader as DesktopHeader};
