import {Icon, Input, message, Spin} from 'antd';
import React, {useEffect} from 'react';

import {Connect} from './Connect';
import {Disconnect} from './Disconnect';

import './Instantly.scss';
import {brandConfig} from '@growth-x/ui';

export const Instantly = ({
  isLoading,
  isActionLoading,
  isTokenVerified,
  connect,
  disconnect,
  verifyToken,
  error,
  client,
}) => {
  useEffect(() => {
    verifyToken();
  }, []);
  return (
    <div>
      {isLoading && <Spin />}
      {!isLoading && !isTokenVerified && <Connect loading={isActionLoading} connect={connect} error={error} />}
      {!isLoading && isTokenVerified && (
        <Disconnect loading={isActionLoading} disconnect={disconnect} client={client} />
      )}
    </div>
  );
};
