import {Select, Button} from 'antd';
import React from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconWrapper} from '@growth-x/ui';

const {Option} = Select;

export const CampaignsFilter = ({handleChange, filteredCampaigns, filters, clearAndClose}) => {
  return (
    <div className="campaigns-page__filter-wrapper">
      <IconWrapper>
        <FontAwesomeIcon icon={['fas', 'list-ul']} />
      </IconWrapper>
      <Select
        mode="multiple"
        maxTagCount={1}
        placeholder="Select campaign"
        onChange={handleChange}
        value={filteredCampaigns.length > 0 ? filters.campaigns : undefined}
        filterOption={(input, option) =>
          (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        className="campaigns-page__filter-input"
      >
        {filteredCampaigns.map(campaign => (
          <Option key={campaign.id}>{campaign.name}</Option>
        ))}
      </Select>
      <Button onClick={clearAndClose} icon="close" type="link" className="campaigns-page__close-filter" size="small" />
    </div>
  );
};
