import React, {useEffect} from 'react';

export const OnboardingCallForm = ({onSuccessSubmit}) => {
  useEffect(() => {
    const listener = function (event) {
      if (event.origin !== 'https://go.leadoku.io') return false;
      function getEmail(e) {
        return e.data?.meetingsPayload?.bookingResponse?.postResponse?.contact?.email;
      }
      if (event.data.meetingBookSucceeded) {
        const email = getEmail(event);
        onSuccessSubmit(email);
      }
    };
    window.addEventListener('message', listener);

    return () => {
      window.removeEventListener('message', listener);
    };
  }, []);
  return (
    <iframe
      title={'Leadoku Onboarding Call'}
      src="https://go.leadoku.io/meetings/leadoku/leadoku-rr?embed=true"
      style={{border: 'none', height: '700px', width: '100%'}}
    />
  );
};
