import {Button, Dropdown, Icon, Input, Menu, message, Popover, Tooltip, Upload} from 'antd';
import {UploadFile} from 'antd/lib/upload/interface';
import React, {useEffect, useState} from 'react';

import {findIconDefinition} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Template} from '@growth-x/types';
import {
  ConfirmationModal,
  CONSTANTS,
  formatAttachmentName,
  getCompaniesReplacer,
  JaroWinklerDistance,
  replaceKeys,
  STRINGS,
  TemplatesMenu,
  IconWrapper,
} from '@growth-x/ui';

import {ReactComponent as MagicIcon} from '../../../assets/images/magic-icon.svg';
import {SelectNoteMember} from '../selectNoteMember';
import {SuggestionFeedback} from './SuggestionFeedback';

const {TextArea} = Input;

const sendButtonItems = [
  {
    title: 'Message',
    type: 'default',
    icon: 'message',
  },
  {
    title: 'Note',
    type: 'note',
    icon: 'paper-clip',
  },
  {
    title: 'Email',
    type: 'email',
    icon: 'mail',
  },
];

const defaultAttachment = {
  lastModified: '',
  lastModifiedDate: {},
  name: '',
  size: '',
  type: '',
  webkitRelativePath: '',
};

export function SendMessage({
  conversation,
  user,
  templates,
  getCampaignById,
  createClientAttachment,
  clientAttachments,
  deleteClientAttachment,
  isInvalidConversation,
  handleSendMessage,
  toggleArchived,
  unmarkConversationYourTurn,
  createConversationNote,
  members,
  csmList,
  client,
  team_member,
  suggestTemplate,
  saveSuggestionFeedback,
  setHeaderModal,
  moveFromPromoPlan,
}) {
  const [messageType, setMessageType] = useState('default');
  const [subject, setSubject] = useState('');
  const [newMessage, setNewMessage] = useState({value: '', template_id: null, score: 0});
  const [isMemberPopoverVisible, setIsMemberPopoverVisible] = useState(false);
  const [noteMentions, setNoteMentions] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [templatesDropdownVisible, setTemplatesDropdownVisible] = useState(false);
  const [newMessageAttachment, setNewMessageAttachment] = useState(defaultAttachment as any);
  const [followupTemplate, setFollowupTemplate] = useState(null);
  const [suggestTemplateLoading, setSuggestTemplateLoading] = useState(false);
  const [attachmentPopoverVisible, setAttachmentPopoverVisible] = useState(false);
  const [suggestionFeebackVisible, setSuggestionFeebackVisible] = useState(false);
  const [showUpgradeConfirmation, setShowUpgradeConfirmation] = useState(false);

  const attachmentName = formatAttachmentName(newMessageAttachment.name);
  const allTemplates = followupTemplate ? [followupTemplate, ...templates] : [...templates];
  const filteredTemplates = searchText
    ? allTemplates.filter((template: Template) => template.title.toLowerCase().includes(searchText.toLowerCase()))
    : allTemplates;
  const toggleTemplatesModal = () => setTemplatesDropdownVisible(value => !value);

  const getFollowupTemplate = async () => {
    const campaign = await getCampaignById(conversation.campaign_id);
    if (campaign && campaign.type.includes('acquisition')) {
      const followupTemplate = {
        id: -1,
        title: `${campaign.name} (Original Followup)`,
        text: campaign.followup_message,
      };
      setFollowupTemplate(followupTemplate);
    }
  };

  useEffect(() => {
    getFollowupTemplate();
  }, []);

  const onOverlayClick = ({item}) => setMessageType(item.props.eventKey);

  const sendButtonOverlay = (
    <Menu onClick={onOverlayClick} selectedKeys={[messageType]}>
      {sendButtonItems.map(({type, title, icon}) => (
        <Menu.Item key={type} title={title}>
          <Icon type={icon} /> {title}
        </Menu.Item>
      ))}
    </Menu>
  );

  const onChangeAttachment = () => (newMessageAttachment ? setNewMessageAttachment(defaultAttachment) : null);

  const filterTemplates = (value: string) => setSearchText(value);

  const onSend = async () => {
    let isFileAttached = false;
    let attachmentId = -1;
    if (messageType === 'note') {
      createConversationNote({
        team_member: team_member ? team_member.id : undefined,
        client: client.id,
        inbox: conversation.id,
        note: newMessage.value,
        mentions: noteMentions,
      });
      setNewMessage({value: '', template_id: null, score: 0});
      return;
    } else if (messageType === 'email') {
      if (conversation.receiver.email) {
        window.open(
          `mailto:${conversation.receiver.email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
            newMessage.value
          )}`
        );
      } else {
        message.warning('There is no email for this receiver.');
      }
    } else {
      if (newMessageAttachment.name !== '') {
        isFileAttached = true;
        attachmentId = clientAttachments?.list?.data?.find(
          attachment => attachment.attachment_name === newMessageAttachment.name
        )?.id;
        if (!attachmentId) {
          const attachmentData = await createClientAttachment(newMessageAttachment);
          attachmentId = attachmentData?.id;
        }
      }
      let template_id = newMessage.template_id;
      if (newMessage.score < 0.8) {
        template_id = null;
      }

      const additional_data = {
        message_content: newMessage.value,
        campaign_id: conversation.campaign_id,
        template_id,
      };

      if (isFileAttached && attachmentId) {
        // WIP not supporting multiple attachments yet. (static indexing)
        const attachmentsList = [{id: attachmentId}];
        additional_data['attachments'] = attachmentsList;
      }

      await handleSendMessage({
        additional_data,
        receiver_id: conversation.receiver.id,
      });

      setNewMessageAttachment(defaultAttachment);
      setNewMessage({value: '', template_id: null, score: 0});
    }

    if (conversation.archived) {
      await toggleArchived();
    }
    if (conversation.your_turn) {
      unmarkConversationYourTurn({id: conversation.id});
    }
  };

  const onSuggestTemplate = async () => {
    try {
      setSuggestTemplateLoading(true);
      const suggestion = await suggestTemplate(conversation.id);
      const suggestedTemplateId = suggestion?.suggested_template_id;
      if (suggestedTemplateId) {
        setSuggestionFeebackVisible(true);
        inputTemplate({key: suggestedTemplateId});
      }
    } finally {
      setSuggestTemplateLoading(false);
    }
  };

  const preparedTemplate = template => {
    const companiesReplacer = getCompaniesReplacer(template.text, conversation.receiver);

    const keys = ['{user.first_name}', '{receiver.first_name}', '{receiver.last_name}', ...companiesReplacer.keys];

    const values = [
      user.first_name,
      conversation.receiver.first_name,
      conversation.receiver.last_name,
      ...companiesReplacer.values,
    ];

    return replaceKeys(template.text, keys, values);
  };
  const inputTemplate = event => {
    setTemplatesDropdownVisible(false);
    let template = templates.find(template => parseInt(template.id) === parseInt(event.key));
    if (!template && event.key === '-1') {
      template = followupTemplate;
    }

    if (template) {
      const templateId = template.id > -1 ? template.id : null;
      const message = preparedTemplate(template);
      setNewMessage({value: message, template_id: templateId, score: 1});
    }
  };
  const inputNoteMember = (member: any, type: string) => {
    setIsMemberPopoverVisible(false);
    const newMember = {
      [type]: member.id,
    };
    const updatedNoteMentions = [
      ...noteMentions.filter(m => {
        if (newMember.client && m.client) {
          return m.client !== newMember.client;
        } else if (newMember.team_member && m.team_member) {
          return m.team_member !== newMember.team_member;
        } else if (newMember.csm && m.csm) {
          return m.csm !== newMember.csm;
        }
        return true;
      }),
      newMember,
    ];
    setNoteMentions(updatedNoteMentions);
    const memberNameWithoutSpaces = member.name.replace(/ /g, ' '); // we want to highlight full member name with space symbols
    const newMessageValue = `${newMessage.value.substring(
      0,
      newMessage.value.lastIndexOf('@')
    )}@${memberNameWithoutSpaces}`;
    setNewMessage({...newMessage, value: newMessageValue});
  };
  const addNewMember = () => {
    setIsMemberPopoverVisible(false);
    setHeaderModal('members');
  };

  const handleFileClick = async (file: UploadFile) => {
    setNewMessageAttachment(file);
    setAttachmentPopoverVisible(false);
  };

  const toggleMemberPopover = () => {
    setIsMemberPopoverVisible(!isMemberPopoverVisible);
  };
  const selectedMessageType = sendButtonItems.find(item => item.type === messageType);

  return (
    <div className={`conversation__send-message-container ${messageType}`} style={{position: 'relative'}}>
      {messageType === 'note' && (
        <Button onClick={toggleMemberPopover} type="link" size="small" className="conversation__mention-button">
          @<Icon type="down" />
        </Button>
      )}
      {messageType === 'email' && (
        <Input
          style={{marginBottom: '10px'}}
          value={subject}
          onChange={e => setSubject(e.target.value)}
          placeholder={'Type the email subject message...'}
          maxLength={250}
          disabled={isInvalidConversation}
        />
      )}
      <SelectNoteMember
        client={client}
        team_member={team_member}
        members={members}
        csmList={csmList}
        isVisible={isMemberPopoverVisible}
        onVisibleChange={setIsMemberPopoverVisible}
        onSelect={inputNoteMember}
        onAddNewMember={addNewMember}
      >
        <div
          style={{
            border: '1px solid #4ab1ff',
            background: selectedMessageType.type === 'note' ? 'var(--color-note)' : '#fff',
            paddingTop: 30,
            borderRadius: 4,
          }}
        >
          <TextArea
            value={newMessage.value}
            onChange={ev => {
              if (messageType === 'note') {
                const isDeletion = (ev.nativeEvent as any)?.inputType === 'deleteContentBackward';
                if (
                  ev.target.value &&
                  !isDeletion &&
                  (ev.target.value === '@' || ev.target.value.endsWith('\n@') || ev.target.value.endsWith(' @'))
                ) {
                  setIsMemberPopoverVisible(true);
                }
              }
              if (!ev.target.value) {
                setNewMessage({value: ev.target.value, template_id: null, score: 0});
              } else {
                let score = newMessage.score;
                if (ev.target.value) {
                  const template = templates.find(
                    template => parseInt(template.id) === parseInt(newMessage.template_id)
                  );
                  if (template) {
                    const message = preparedTemplate(template);
                    score = JaroWinklerDistance(message, ev.target.value, {});
                  }
                }
                setNewMessage({...newMessage, value: ev.target.value, score});
              }
            }}
            placeholder={'Type your message...'}
            className={`conversation__textarea ${messageType}`}
            disabled={isInvalidConversation}
          />
          {client.signature_enabled ? (
            <div className="inbox-message-text-area__whitelabel-signature">
              <span>{STRINGS.free_trial_signature}</span>
              <ConfirmationModal
                visible={showUpgradeConfirmation}
                message="By clicking yes, you will remove the signature and upgrade to a paying subscription of $99 per month? Are you sure to proceed?"
                onOk={() => {
                  moveFromPromoPlan().then(() => setShowUpgradeConfirmation(false));
                }}
                onCancel={() => setShowUpgradeConfirmation(false)}
              />
              <Button
                size="small"
                type="primary"
                onClick={() => setShowUpgradeConfirmation(true)}
                style={{marginLeft: '5px', fontSize: '.9em'}}
              >
                Upgrade
              </Button>
            </div>
          ) : null}
        </div>
      </SelectNoteMember>
      {attachmentName && (
        <Button style={{marginTop: '10px'}} onClick={onChangeAttachment} disabled={isInvalidConversation}>
          <Icon type="file" />
          {attachmentName}
          <Icon type="close-circle" />
        </Button>
      )}
      <div className="conversation__input-actions">
        <Button type="primary" onClick={onSend}>
          {messageType === 'note' ? 'Add note' : 'Send'}
        </Button>
        <Dropdown className="conversation__message-type-select" overlay={sendButtonOverlay}>
          <Button size="small">
            <Icon type={selectedMessageType.icon} />
            {selectedMessageType.title}
            <Icon type="down" />
          </Button>
        </Dropdown>
        <Popover
          placement="top"
          trigger="click"
          visible={attachmentPopoverVisible}
          onVisibleChange={setAttachmentPopoverVisible}
          title={'Select a file'}
          content={
            <div className="conversation__attachment-dropdown">
              <Upload
                multiple={false}
                showUploadList={true}
                fileList={clientAttachments?.list?.data.map(({id, attachment_name}) => {
                  return {
                    name: attachment_name,
                    uid: id,
                  };
                })}
                accept=".csv,.xlsx,.doc,.pdf,.txt,.html,.htm,.jpeg,.jpg,.png"
                beforeUpload={(file: any) => {
                  const isSizeValid = file.size <= CONSTANTS.limits.max_attachment_size;
                  const isNameValid = CONSTANTS.regexes.attachment_name.test(file.name);
                  if (!isNameValid) {
                    message.warning('File name should contain only letters, digits, "_" or "-"');
                  }
                  if (!isSizeValid) {
                    message.warning('The uploaded file exceeds max file size of 20 MB, please upload a smaller file.');
                  }
                  if (isSizeValid && isNameValid) {
                    setNewMessageAttachment(file);
                  }
                  return isSizeValid && isNameValid;
                }}
                onRemove={async file => {
                  await deleteClientAttachment(file.uid);
                }}
                disabled={messageType === 'note'}
                onPreview={(file: UploadFile) => handleFileClick(file)}
              >
                <Tooltip title={'Upload a new file'}>
                  <Button>
                    <Icon type="plus" />
                  </Button>
                </Tooltip>
              </Upload>
            </div>
          }
        >
          <Button style={{marginLeft: '5px'}} disabled={isInvalidConversation}>
            <Icon type="paper-clip" />
          </Button>
        </Popover>

        <Dropdown
          disabled={isInvalidConversation}
          overlayClassName="conversation__templates-dropdown"
          overlay={
            <TemplatesMenu
              filterTemplates={filterTemplates}
              searchText={searchText}
              filteredTemplates={filteredTemplates}
              inputTemplate={inputTemplate}
              onClose={() => setTemplatesDropdownVisible(false)}
              className="conversation__templates-trigger"
              inputMessage={null}
              createTemplate={null}
            />
          }
          placement="topCenter"
          visible={templatesDropdownVisible}
        >
          <Button
            className="conversation__templates-trigger"
            disabled={isInvalidConversation}
            onClick={toggleTemplatesModal}
            style={{marginLeft: 5}}
            type="dashed"
          >
            <IconWrapper>
              <FontAwesomeIcon icon={findIconDefinition({prefix: 'fas', iconName: 'edit'})} />
            </IconWrapper>
            Templates
          </Button>
        </Dropdown>

        <SuggestionFeedback
          visible={suggestionFeebackVisible}
          setVisible={setSuggestionFeebackVisible}
          onSave={saveSuggestionFeedback}
          conversation={conversation.id}
        >
          <Tooltip title={'Suggest template'}>
            <Button
              onClick={onSuggestTemplate}
              className="conversation__suggest-template-button"
              shape="circle"
              type="link"
              loading={suggestTemplateLoading}
            >
              <MagicIcon />
            </Button>
          </Tooltip>
        </SuggestionFeedback>
      </div>
    </div>
  );
}
