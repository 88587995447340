import {Rb2bService} from '../../services';

export const GET_RB2B_LATEST_EVENT_REQUEST = 'GET_RB2B_LATEST_EVENT_REQUEST';
export const GET_RB2B_LATEST_EVENT_SUCCESS = 'GET_RB2B_LATEST_EVENT_SUCCESS';
export const GET_RB2B_LATEST_EVENT_FAILURE = 'GET_RB2B_LATEST_EVENT_FAILURE';

export const rb2bActions = {
  getLatestEvent,
  updateLatestEventWS,
};

function getLatestEvent() {
  return async (dispatch: any) => {
    dispatch(request());
    return await Rb2bService.getLatestEvent().then(
      ({data}: any) => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return {type: GET_RB2B_LATEST_EVENT_REQUEST};
  }
  function success(data) {
    return {type: GET_RB2B_LATEST_EVENT_SUCCESS, data};
  }
  function failure(error) {
    return {type: GET_RB2B_LATEST_EVENT_FAILURE, error};
  }
}

function updateLatestEventWS(event) {
  return async (dispatch: any) => {
    dispatch({type: GET_RB2B_LATEST_EVENT_SUCCESS, data: event});
  };
}
