export class Rb2bService {
    client: any;
    constructor({client}: any) {
      this.client = client;
    }
    serviceName = 'rb2b';
  
    getLatestEvent() {
      return this.client.get(`${this.serviceName}/latest_event`, {});
    }
  }
  