import {Button, Dropdown, Menu, DatePicker, Tooltip} from 'antd';
import moment from 'moment-timezone';
import React, {useRef, useEffect} from 'react';

import {findIconDefinition} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconWrapper} from '@growth-x/ui';

import './snoozeConversationButton.css';

const defaultSnoozeTime = {hour: 9, minute: 0, second: 0, millisecond: 0};

const getSnoozeOptions = () => {
  const today = moment();
  return [
    {
      label: 'Later today',
      description: 'In 3 hours',
      value: '1',
    },
    {
      label: 'Tomorrow',
      description: today.add(1, 'day').set(defaultSnoozeTime).format('ddd ha'),
      value: '2',
    },
    {
      label: 'Monday',
      description: today.startOf('isoWeek').add(1, 'week').day('monday').set(defaultSnoozeTime).format('ddd ha'),
      value: '3',
    },
    {
      label: 'One week',
      description: today.add(1, 'week').set(defaultSnoozeTime).format('MMM D'),
      value: '4',
    },
    {
      label: 'One month',
      description: today.add(1, 'month').set(defaultSnoozeTime).format('MMM D'),
      value: '5',
    },
  ];
};

function SnoozeMenu({
  setSnoozeTimer,
  setSnoozeDatePickerOpen,
  setSnoozeDropdownVisible,
  snoozeDatePickerOpen,
  snoozeDatePickerStatus,
  setCustomSnoozeTimer,
}) {
  const snoozeMenu = useRef(null);

  useEffect(() => {
    const closeMenu = function (e) {
      const calendarPicker = document.querySelector('.ant-calendar-picker-container');
      if (
        !snoozeMenu.current.contains(e.target) &&
        (typeof e.target.className === 'string' && !e.target.className.includes('snooze_trigger')) &&
        (calendarPicker ? !calendarPicker.contains(e.target) : true)
      ) {
        setSnoozeDropdownVisible(false);
      }
    };

    window.addEventListener('click', closeMenu);

    return () => {
      window.removeEventListener('click', closeMenu);
    };
  }, []);

  return (
    <div ref={snoozeMenu}>
      <Menu onClick={setSnoozeTimer}>
        {getSnoozeOptions().map(option => (
          <Menu.Item key={option.value}>
            {option.label} <span className="conversation__snooze-description">{option.description}</span>
          </Menu.Item>
        ))}
      </Menu>
      <Button type="link" onClick={() => setSnoozeDatePickerOpen(value => !value)}>
        Custom
      </Button>
      <DatePicker
        style={{
          overflow: 'hidden',
          height: 1,
          visibility: 'hidden',
          display: 'inline-block',
        }}
        onOpenChange={snoozeDatePickerStatus}
        showToday={false}
        open={snoozeDatePickerOpen}
        onChange={setCustomSnoozeTimer}
      />
    </div>
  );
}

export const SnoozeConversationButton = ({conversation, snoozeConversation, toggleArchived}: any) => {
  const [snoozeDatePickerOpen, setSnoozeDatePickerOpen] = React.useState(false);
  const [snoozeDropdownVisible, setSnoozeDropdownVisible] = React.useState(false);
  const snoozeDatePickerStatus = status => {
    if (!status) {
      setSnoozeDatePickerOpen(false);
    }
  };
  const snoozeConversationWrapper = (id, time) => {
    snoozeConversation(id, time);
    setSnoozeDropdownVisible(false);
  };
  const setCustomSnoozeTimer = (value?: any) => {
    if (!value) {
      snoozeConversationWrapper(conversation.id, null);
    } else {
      let snoozeTime = value.set({
        hour: 9,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      snoozeTime = snoozeTime.utc().format();
      snoozeConversationWrapper(conversation.id, snoozeTime);
    }
    if (conversation.archived) {
      toggleArchived();
    }
  };

  const setSnoozeTimer = event => {
    const today = moment();
    let until;
    switch (event.key) {
      case '1': // in 3 hours
        until = today.add(3, 'hours');
        break;
      case '2': // tomorrow
        until = today.add(1, 'day');
        until = until.set(defaultSnoozeTime);
        break;
      case '3': // next monday
        until = today.startOf('isoWeek').add(1, 'week').day('monday');
        until = until.set(defaultSnoozeTime);
        break;
      case '4': // one week
        until = today.add(1, 'week');
        until = until.set(defaultSnoozeTime);
        break;
      case '5': // one month
        until = today.add(1, 'month');
        until = until.set(defaultSnoozeTime);
        break;
      default:
        until = today.add(3, 'hours');
        break;
    }
    snoozeConversationWrapper(conversation.id, until.tz('UTC').format());
    if (conversation.archived) {
      toggleArchived();
    }
  };

  if (conversation.snoozed_until) {
    return (
      <Tooltip title={'Unsnooze'}>
        <Button
          className="snooze_button enabled"
          onClick={() => setCustomSnoozeTimer()}
          style={{marginLeft: 5}}
          type="dashed"
        >
          <IconWrapper>
            <FontAwesomeIcon
              icon={findIconDefinition({
                prefix: 'fas',
                iconName: 'clock',
              })}
            />
          </IconWrapper>
        </Button>
      </Tooltip>
    );
  } else {
    return (
      <Dropdown
        overlayClassName="conversation__snooze-dropdown"
        overlay={
          <SnoozeMenu
            setSnoozeTimer={setSnoozeTimer}
            setSnoozeDatePickerOpen={setSnoozeDatePickerOpen}
            setSnoozeDropdownVisible={setSnoozeDropdownVisible}
            snoozeDatePickerOpen={snoozeDatePickerOpen}
            snoozeDatePickerStatus={snoozeDatePickerStatus}
            setCustomSnoozeTimer={setCustomSnoozeTimer}
          />
        }
        placement="topCenter"
        visible={snoozeDropdownVisible}
      >
        <Tooltip title={' Snooze'}>
          <Button
            onClick={() => setSnoozeDropdownVisible(!snoozeDropdownVisible)}
            style={{marginLeft: 5}}
            type="dashed"
            className="snooze_trigger"
          >
            <IconWrapper>
              <FontAwesomeIcon
                icon={findIconDefinition({
                  prefix: 'fas',
                  iconName: 'clock',
                })}
              />
            </IconWrapper>
          </Button>
        </Tooltip>
      </Dropdown>
    );
  }
};
