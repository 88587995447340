import {Modal, Row, Radio, Col, Button} from 'antd';
import React from 'react';

import checkMark from '../../assets/svg/check_mark.svg';
import './ChoosePlanLeadokuModal.scss';

export const ChoosePlanLeadokuModal = ({visible, onCancel, period, onChangePeriod, onClick}) => (
  <Modal visible={visible} footer={null} destroyOnClose={true} onCancel={onCancel} width={800} title="Checkout">
    <div className="plans_container">
      <Row type="flex" justify="center" style={{marginBottom: 45}}>
        <Radio.Group className="choose_plan" value={period} buttonStyle="solid" onChange={onChangePeriod}>
          <Radio.Button value="monthly">Monthly</Radio.Button>
          <Radio.Button value="annual">
            Annual <span className="discount">-20%</span>
          </Radio.Button>
        </Radio.Group>
      </Row>
      <Row gutter={[32, 8]}>
        {LEADOKU_PLANS.map(plan => (
          <Col key={plan.key} span={12}>
            <div className="plan_container">
              <h3>{plan.title}</h3>
              <div className="price_container">
                <span className="price">{plan.prices[period].price}</span>
                <span className="description">{plan.prices[period].description}</span>
              </div>
              <Button type="primary" onClick={() => onClick(plan)}>
                {plan.button}
              </Button>
              <ul>
                {plan.features.map(feature => (
                  <li key={feature} style={{listStyleImage: `url(${checkMark})`}}>
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  </Modal>
);

const LEADOKU_PLANS = [
  {
    key: 'startup',
    title: 'Startup',
    prices: {
      monthly: {
        price: '$99',
        description: '/mo',
      },
      annual: {
        price: '$79',
        description: '/mo',
      },
    },
    button: 'Leads Trial',
    features: [
      'Cloud-based platform',
      'Country-specific dedicated IP address',
      'Seamless profile opening with consistent IP usage',
      'Automated LinkedIn profile warming',
      'Advanced algorithms for managing activity limits',
      'Emulation of human-like actions',
      'Unlimited campaign capacity',
      'Campaign prioritization and organization',
      'Ability to include images and GIFs',
      'Comprehensive, step-by-step campaign analytics',
      'Unified dashboard for complete visibility',
      'Flexible scheduling options',
      'Campaign restoration and recovery features',
      'Tags and notes for conversation tracking',
      'Unlimited templates library',
      'Built-in duplicate detection',
      'CRM integration capabilities',
      'AI-driven hot lead identification',
      'AI-assisted rapid campaign creation',
    ],
  },
  {
    key: 'custom',
    title: 'Enterprise',
    prices: {
      monthly: {
        price: 'Custom',
        description: '+10 Users',
      },
      annual: {
        price: 'Custom',
        description: '+10 Users',
      },
    },
    button: 'Talk To Sales',
    features: [
      'Everything in Small Business',
      'Enhanced team collaboration features',
      'Centralized management of all campaigns',
      'Dedicated Customer Success Manager for personalized support',
      'Expert-level customer service',
      'Executive-level analytics and reporting',
      'Seamless integrations with third-party tools',
      'White-label solution for branding flexibility',
      'Automated email outreach',
      'Website visitor targeting campaigns',
      'Multichannel outreach capabilities',
      'AI-powered auto-responses',
    ],
  },
];
