import {connect} from 'react-redux';

import {LazyBillingDetails} from '@growth-x/ui';

import {billingActions} from '../../redux/billing';
import {headerModalActions} from '../../redux/headerModal';

function mapState(state: any) {
  return {
    client: state.client.data,
    isAdmin: state.settings.isAdmin,
    loading: state.billing.loading,
    info: state.billing.info,
    contactEmails: state.billing.contactEmails,
    onClose: () => headerModalActions.setHeaderModal(undefined),
  };
}

const actionCreators = {
  setHeaderModal: headerModalActions.setHeaderModal,
  onUpdate: billingActions.updateSubscription,
  onUpdateLeadoku: billingActions.updateSubscriptionLeadoku,
  endTrial: billingActions.endTrial,
  onUpdateStatus: billingActions.updateSubscriptionStatus,
  onRemoveCoupon: billingActions.removeBillingCoupon,
  onGetInfo: billingActions.getBillingInfo,
  getBillingContactEmails: billingActions.getBillingContactEmails,
  leadokuReactivate: billingActions.leadokuReactivate,
  removeScheduledChanges: billingActions.removeScheduledChanges,
  createBillingContactEmail: billingActions.createBillingContactEmail,
  updateBillingContactEmail: billingActions.updateBillingContactEmail,
  deleteBillingContactEmail: billingActions.deleteBillingContactEmail,
  upsertBillingContactEmail: billingActions.upsertBillingContactEmail,
  moveFromPromoPlan: billingActions.moveFromPromoPlan,
};

export const BillingDetailsContainer = connect(mapState, actionCreators)(LazyBillingDetails);
