import {brandConfig} from '@growth-x/ui';
import {Input, Row, Button, message, Icon} from 'antd';
import React from 'react';

export const Disconnect = ({loading, disconnect, client}) => {
  const webhookLink = `${brandConfig.instantly_link}?analytics_code=${client.analytics_code}`;
  const copyToClibpoard = () => {
    navigator.clipboard.writeText(webhookLink);
    message.success('Link copied to clipboard');
  };

  return (
    <div>
      <Row type="flex" className="instantly-disconnect">
        <Input placeholder={'Instantly API Key'} disabled value="****************" />
        <Button type="danger" onClick={disconnect} loading={loading}>
          Disconnect
        </Button>
      </Row>
      <div className="instantly-configure-campaign">
        To configure your Instantly acquisition campaign, copy the URL below go to{' '}
        <a href="https://app.instantly.ai/" target="_blank" rel="noreferrer noopener">
          app.instantly.ai
        </a>{' '}
        and follow the instructions provided{' '}
        <a
          href="https://help.instantly.ai/en/articles/6261906-how-to-use-webhooks"
          rel="noreferrer noopener"
          target="_blank"
        >
          here.
        </a>
        <Input
          className="instantly-configure-campaign__link"
          value={webhookLink}
          addonAfter={<Icon style={{cursor: 'pointer'}} onClick={copyToClibpoard} type="copy" />}
        />
      </div>
    </div>
  );
};
