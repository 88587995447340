export const websocketActions = {
  wsTest,
};

export const WS_USER_UPDATED = 'data_updated_users_updated';
export const WS_USER_CREATED = 'data_updated_users_created';
export const WS_CAMPAIGN_USER_ADDED = 'data_updated_campaign_users_added';
export const WS_CAMPAIGN_USER_REMOVED = 'data_updated_campaign_users_removed';
export const WS_APP_TASK_CREATED = 'data_updated_app_tasks_created';
export const WS_APP_TASK_UPDATED = 'data_updated_app_tasks_updated';
export const WS_INBOX_CONVERSATION_UPDATED = 'data_updated_conversations_updated';
export const WS_CLIENT_LOGOUT = 'logout';
export const WS_USER_ARCHIVED = 'data_updated_users_archived';
export const WS_USER_UNARCHIVED = 'data_updated_users_unarchived';
export const WS_INBOX_CONVERSATION_UNSNOOZED = 'data_updated_conversations_unsnoozed';
export const WS_NOTIFICATION_CREATED = 'data_updated_notifications_created';
export const WS_CLOUD_INSTANCE_STATE_CHANGE = 'data_updated_cloud_instance_state_change';
export const WS_CAMPAIGN_CREATED = 'data_updated_campaigns_created';
export const WS_CAMPAIGN_UPDATED = 'data_updated_campaigns_updated';
export const WS_CAMPAIGN_REMOVED = 'data_updated_campaigns_removed';
export const WS_CLIENT_UPDATED = 'data_updated_clients_updated';
export const WS_RB2B_UPDATED = 'data_updated_rb2b_test';

// TODO ask Vitalik
function wsTest(text: string) {
  return (dispatch: any, getState: any, {emit}: any) => {
    // dispatch({
    //   type: SOME_ACTION,
    //   payload: {
    //     text,
    //   }
    // }),
    emit({data_type: 'campaigns22'});
  };
}
