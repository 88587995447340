import {connect} from 'react-redux';

import {Instantly} from '@growth-x/ui';

import {instantlyActions} from '../redux/instantly';

function mapState(state: any) {
  return {
    isLoading: state.instantly.isLoading,
    isActionLoading: state.instantly.isActionLoading,
    isTokenVerified: state.instantly.isTokenVerified,
    error: state.instantly.error,
    client: state.client.data,
  };
}

const actionCreators = {
  verifyToken: instantlyActions.verifyToken,
  connect: instantlyActions.connect,
  disconnect: instantlyActions.disconnect,
};

export const InstantlyContainer = connect(mapState, actionCreators)(Instantly);
