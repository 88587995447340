import {
  Alert,
  Button,
  Col,
  Divider,
  Dropdown,
  Form,
  Icon,
  Input,
  Menu,
  Modal,
  Row,
  Select,
  Slider,
  Switch,
  Tooltip,
} from 'antd';
import {ErrorMessage, Formik} from 'formik';
import moment from 'moment-timezone';
import * as React from 'react';
import {isMobile} from 'react-device-detect';

import {Client, User} from '@growth-x/types';
import {brandConfig} from '@growth-x/ui';

import {AdminFeatureIcon, InfoIcon, LockIcon} from '../';
import {checkCloudOffline, STRINGS} from '../..';
import './userEditModal.css';
import {SwitchRow} from '../campaignDetails/common';
import {ConfirmationModal} from '../confirmationModal';
import {UserProxy} from './userProxy';
import {UserRequirementsPopover} from './userRequirementsPopover';
import {UserRequirementsTooltip} from './userRequirementsTooltip';

interface UserEditModalProps {
  user: User;
  users: User[];
  client: Client;
  members: any;
  visible: boolean;
  isAdmin: boolean;
  loading: boolean;
  error: string;
  isNewUser: boolean;
  customProxyVisible: boolean;
  affiliatedClients: any;
  serverState?: string;
  handleOk: (user: User, isNew: boolean) => void;
  handleCancel: () => void;
  cleanup: () => void;
  archiveUser: (userId: number) => void;
  unarchiveUser: (userId: number) => void;
  scheduleArchive: (userId: number) => void;
  cancelScheduledArchive: (userId: number) => void;
  revealPassword: (userId: number) => void;
  changeUserState: (userId: number, value: boolean) => void;
  snoozeLoginError: (userId: number, days: number) => void;
  replaceUser: (userId: number) => void;
  validateUser: (userId: number) => void;
  openUser: (userId: number) => void;
  handleTwitter: (userId: number, state: boolean) => void;
  trackIntercomEvent: (tag: any, value: any, condition?: any) => void;
  updateSnSubsctiptionSelect: (userId: number, value: number) => void;
  clearCookies: (userId: number) => void;
  resetLoginErrorDate: (userId: number) => void;
  proxyLocations: any;
  createUserProxy: (userId: number, location: any) => Promise<boolean>;
  createUserCustomProxy: (params: any) => void;
  removeUserCustomProxy: (params: any) => void;
  updateCustomProxyVisible: (params: any) => void;
  updateUserDetails: (params: any) => void;
  getAffiliatedClients: () => void;
  moveUser: (params: any, withCampaigns: boolean) => void;
  replaceUserProxy: (params: any) => void;
}

interface UserEditModalState {
  confirmationVisible: boolean;
  login_error_snooze_days: number;
  sortedLocations: any;
  selectedClient: any;
  moveUserWithCampaigns: boolean;
}

const snSubsctiptionOptions = [
  {label: 'First', value: 1},
  {label: 'Second', value: 2},
  {label: 'Third', value: 3},
];

const stringifyLocation = location => {
  return `${location.country ? location.country + '/' : ''}${location.state ? location.state + '/' : ''}${
    location.city ? location.city : ''
  }`;
};

export class UserEditModal extends React.Component<UserEditModalProps, UserEditModalState> {
  state = {
    confirmationVisible: false,
    login_error_snooze_days: 1,
    sortedLocations: [],
    selectedClient: null,
    moveUserWithCampaigns: true,
  };

  componentDidMount() {
    const london = this.props.proxyLocations.find(l => l && l[2] && l[2] === 'London');
    const ashburn = this.props.proxyLocations.find(l => l && l[2] && l[2] === 'Ashburn');
    const other = this.props.proxyLocations.filter(l => l && l[2] && !['London', 'Ashburn'].includes(l[2]));
    const noneOption = ['none'];
    this.setState({sortedLocations: [noneOption, london, ashburn, ...other]});
    if (this.props.isAdmin) {
      this.props.getAffiliatedClients();
    }
  }

  getSubmitTextButton = (currentFirstName, currentPassword): string => {
    const {first_name: originalFirstName} = this.props.user;

    if (this.props.isNewUser || originalFirstName !== currentFirstName || currentPassword) {
      return 'Save and Validate';
    }

    return 'Save';
  };

  handleValidate = (values: any) => {
    const errors: any = {};
    if (!values.first_name) {
      errors.first_name = 'Required';
    }
    if (!values.last_name) {
      errors.last_name = 'Required';
    }
    if (!values.email) {
      errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Must be an email';
    }
    if (!this.props.user.id && !values.password) {
      errors.password = 'Required';
    }
    if (values.weekly_max_messages_limit > 200) {
      errors.weekly_max_messages_limit = 'Weekly max messages limit is 200';
    }
    const invites_restricted_max = this.props.user.invites_restricted_max || Number.MAX_SAFE_INTEGER;
    if (!values.max_messages_limit) {
      errors.max_messages_limit = 'Required';
    } else if (values.max_messages_limit > Math.min(this.props.user.max_messages, invites_restricted_max)) {
      errors.max_messages_limit =
        this.props.user.max_messages <= invites_restricted_max
          ? `The max daily invites limit is ${this.props.user.max_messages}`
          : `The max daily invites limit is temporary restricted to ${this.props.user.invites_restricted_max}`;
    }
    if (this.props.client.premium_feature && !values.max_nurturing_message_limit) {
      errors.max_nurturing_message_limit = 'Required';
    }
    if (this.props.customProxyVisible) {
      if (!values.custom_proxy_ip) {
        errors.custom_proxy_ip = 'Required';
      }
      if (!values.custom_proxy_password) {
        errors.custom_proxy_password = 'Required';
      }
      if (!values.custom_proxy_port) {
        errors.custom_proxy_port = 'Required';
      }
      if (!values.custom_proxy_username) {
        errors.custom_proxy_username = 'Required';
      }
    }

    return errors;
  };

  isClientOffline = () => checkCloudOffline(this.props.client.last_activity_date, this.props.serverState);

  handleSubmit = async (values: any) => {
    await this.props.handleOk(
      {
        ...this.props.user,
        ...values,
      },
      false
    );

    if (this.state.selectedClient) {
      await this.props.moveUser(
        {user_id: this.props.user.id, new_client_id: this.state.selectedClient},
        this.state.moveUserWithCampaigns
      );
    }

    const sn_account_selection = values.sn_account_selection;
    if (sn_account_selection !== this.props.user.sn_account_selection) {
      await this.props.updateSnSubsctiptionSelect(this.props.user.id, values.sn_account_selection);
    }

    const location = values.location;
    // if not string - proxy already exist
    if (location && typeof location === 'string') {
      await this.props.createUserProxy(this.props.user.id, location);
    } else if (this.props.customProxyVisible && values.custom_proxy_ip) {
      const params = {
        user: values.user_id,
        ip: values.custom_proxy_ip,
        port: values.custom_proxy_port,
        username: values.custom_proxy_username,
        password: values.custom_proxy_password,
      };
      await this.props.createUserCustomProxy(params);
    }
    this.props.updateCustomProxyVisible(false);
  };

  handleAction = async ({key}: any) => {
    if (key === 'archive') {
      this.props.archiveUser(this.props.user.id);
    } else if (key === 'replace') {
      this.props.replaceUser(this.props.user.id);
    } else if (key === 'validate') {
      this.props.validateUser(this.props.user.id);
    } else if (key === 'unarchive') {
      this.props.unarchiveUser(this.props.user.id);
    } else if (key === 'open') {
      this.props.openUser(this.props.user.id);
    } else if (key === 'cancel-scheduled-archive') {
      this.props.cancelScheduledArchive(this.props.user.id);
    } else if (key === 'schedule-archive') {
      this.props.scheduleArchive(this.props.user.id);
    }
  };

  onClose = () => {
    this.setState({confirmationVisible: true});
    this.props.cleanup();
    this.props.handleCancel();
  };
  onCloseConfirmModal = () => {
    this.setState({confirmationVisible: false});
  };
  onCloseModals = () => {
    this.setState({confirmationVisible: false});
    this.props.cleanup();
    this.props.handleCancel();
  };
  showNurturingLimit = (client: Client) => {
    return client.premium_feature && client.manage_sub;
  };

  revealPassword = () => {
    this.props.revealPassword(this.props.user.id);
  };

  snoozeLoginError = () => {
    this.props.snoozeLoginError(this.props.user.id, this.state.login_error_snooze_days);
  };

  getMenu = isArchiveDisabled => {
    if (this.props.user.archived && this.props.isAdmin) {
      return (
        <Dropdown
          overlay={
            <Menu onClick={this.handleAction}>
              <Menu.Item key="unarchive">
                <AdminFeatureIcon />
                Unarchive
              </Menu.Item>
            </Menu>
          }
          trigger={['click']}
        >
          <div>
            <Icon type="more" style={{color: 'var(--color-tooltip-icon)'}} className="ant-modal-close-icon" />
          </div>
        </Dropdown>
      );
    }
    if (this.props.user.id && this.props.client.affiliate) {
      return (
        <Dropdown
          overlay={
            <Menu onClick={this.handleAction}>
              <Menu.Item key="validate" disabled={this.isClientOffline()}>
                Validate
              </Menu.Item>
              {this.props.client.open_feature && (
                <Menu.Item key="open" disabled={this.isClientOffline()}>
                  Open
                </Menu.Item>
              )}
              <Menu.Item key="replace">
                Replace <InfoIcon message={STRINGS.tooltip_replace_user} />
              </Menu.Item>
              <Menu.Item disabled={isArchiveDisabled} key="archive">
                {isArchiveDisabled ? (
                  <Tooltip title="You cannot have less than 1 users in your subscription.">
                    <span>
                      Archive <InfoIcon message={STRINGS.tooltip_archive_user} />
                    </span>
                  </Tooltip>
                ) : (
                  <span>
                    Archive <InfoIcon message={STRINGS.tooltip_archive_user} />
                  </span>
                )}
              </Menu.Item>
              {this.props.isAdmin &&
                (this.props.user.scheduled_archive_date ? (
                  <Menu.Item key="cancel-scheduled-archive">
                    <AdminFeatureIcon />
                    Cancel Scheduled Archivation
                  </Menu.Item>
                ) : (
                  <Menu.Item key="schedule-archive">
                    <AdminFeatureIcon />
                    Schedule Archive
                  </Menu.Item>
                ))}
            </Menu>
          }
          trigger={['click']}
        >
          <div>
            <Icon type="more" style={{color: 'var(--color-tooltip-icon)'}} className="ant-modal-close-icon" />
          </div>
        </Dropdown>
      );
    }

    return (
      <Dropdown
        overlay={
          <Menu onClick={this.handleAction}>
            <Menu.Item key="validate" disabled={this.isClientOffline()}>
              Validate
            </Menu.Item>
            {this.props.client.open_feature && (
              <Menu.Item key="open" disabled={this.isClientOffline()}>
                Open
              </Menu.Item>
            )}
            {brandConfig.isLeadoku && !this.props.isAdmin && (
              <Menu.Item key="archive">
                <span>
                  Archive <InfoIcon message={STRINGS.tooltip_archive_user} />
                </span>
              </Menu.Item>
            )}
            {this.props.isAdmin && (
              <Menu.Item key="archive">
                <span>
                  <AdminFeatureIcon />
                  Archive <InfoIcon message={STRINGS.tooltip_archive_user} />
                </span>
              </Menu.Item>
            )}
            {this.props.isAdmin &&
              (this.props.user.scheduled_archive_date ? (
                <Menu.Item key="cancel-scheduled-archive">
                  <AdminFeatureIcon />
                  Cancel Scheduled Archivation
                </Menu.Item>
              ) : (
                <Menu.Item key="schedule-archive">
                  <AdminFeatureIcon />
                  Schedule Archive
                </Menu.Item>
              ))}
          </Menu>
        }
        trigger={['click']}
      >
        <div>
          <Icon type="more" style={{color: 'var(--color-tooltip-icon)'}} className="ant-modal-close-icon" />
        </div>
      </Dropdown>
    );
  };

  isLockCreateTeamMember = email => {
    const {users, client, members} = this.props;
    return client.email === email || users.some(u => u.email === email) || members.some(m => m.email === email);
  };

  handleChangeEmail = (value, setFieldValue) => {
    setFieldValue('email', value);
    setFieldValue('create_team_member', !this.isLockCreateTeamMember(value));
  };

  handleLocationChange = value => {
    this.props.updateUserDetails({...this.props.user, proxy: {location: value}});
    value && this.props.updateCustomProxyVisible(false);
  };

  onSwitchCheckedChange = value => {
    value && this.props.updateUserDetails({...this.props.user, proxy: {location: undefined}});
    this.props.updateCustomProxyVisible(!this.props.customProxyVisible);
  };

  isValidForm = errors => Object.keys(errors).length === 0;

  render() {
    const {isAdmin, affiliatedClients} = this.props;
    const isUpdateUser = !!this.props.user.id;
    const isArchiveDisabled = this.props.users.length < 2;
    const isFirstUserForClient = this.props.users.length === 0;
    const twitter_connected =
      !!this.props.user.twitter_creds &&
      !!this.props.user.twitter_creds.access_oauth_token_secret &&
      !!this.props.user.twitter_creds.access_oauth_token;
    return (
      <Modal
        visible={this.props.visible || this.props.loading}
        onCancel={() => null}
        footer={null}
        maskClosable={false}
        title="User Details"
        className="user-edit-modal"
        closeIcon={this.getMenu(isArchiveDisabled)}
      >
        <ConfirmationModal
          visible={this.state.confirmationVisible}
          onOk={this.onCloseModals}
          onCancel={this.onCloseConfirmModal}
        />
        <Formik
          initialValues={{
            first_name: this.props.user.first_name,
            last_name: this.props.user.last_name,
            email: this.props.user.email,
            password: this.props.user.password,
            max_messages_limit: this.props.user.max_messages_limit,
            max_nurturing_message_limit: this.props.user.max_nurturing_message_limit,
            delete_pending_invites: this.props.user.delete_pending_invites,
            auto_update_messages_limits: this.props.user.auto_update_messages_limits,
            weekly_max_messages_limit: this.props.user.weekly_max_messages_limit,
            weekly_invites_limit_via_email: this.props.user.weekly_invites_limit_via_email,
            cookies: this.props.user.cookies,
            user_id: this.props.user.id,
            last_login_error_date: this.props.user.last_login_error_date,
            ignore_actions_with_receivers_from_user: this.props.user.ignore_actions_with_receivers_from_user,
            sn_account_selection: this.props.user.sn_account_selection,
            disabled: this.props.user.disabled,
            create_team_member: this.props.user.create_team_member,
            custom_proxy_ip: this.props.user.proxy?.ip,
            custom_proxy_port: this.props.user.proxy?.port,
            custom_proxy_username: this.props.user.proxy?.username,
            custom_proxy_password: this.props.user.proxy?.password,
            customProxyVisible: this.props.customProxyVisible,
            location: this.props.user?.proxy?.location?.city
              ? stringifyLocation(this.props.user.proxy.location)
              : this.props.user?.proxy?.location,
            basic_linkedin_sub: this.props.user.basic_linkedin_sub,
          }}
          enableReinitialize={true}
          validate={this.handleValidate}
          onSubmit={this.handleSubmit}
        >
          {({values, errors, touched, dirty, handleChange, handleBlur, handleSubmit, setFieldValue}) => (
            <Form onSubmit={handleSubmit} autoComplete="off" layout="vertical">
              <div style={{position: 'relative', padding: '20px 20px 0 20px'}}>
                {!!this.props.user.archived && (
                  <div className="view-only-overlay">
                    <span className="view-only-overlay__text">
                      <Icon type="eye" /> View only
                    </span>
                  </div>
                )}
                <Form.Item label="First Name*:">
                  <Input
                    value={values.first_name}
                    name="first_name"
                    className={errors.first_name && touched.first_name ? 'invalid-input' : ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Type your user first name as it appears on LinkedIn"
                  />
                </Form.Item>
                <ErrorMessage name="first_name" className="error-message" component="div" />

                <Form.Item label="Last Name*:">
                  <Input
                    value={values.last_name}
                    name="last_name"
                    className={errors.last_name && touched.last_name ? 'invalid-input' : ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Type your user last name as it appears on LinkedIn"
                  />
                </Form.Item>
                <ErrorMessage name="last_name" className="error-message" component="div" />

                {this.props.user.linkedin_url && (
                  <Form.Item label="Linkedin Profile:">
                    <a href={this.props.user.linkedin_url} target="_blank" rel="noreferrer">
                      {this.props.user.linkedin_url}
                    </a>
                  </Form.Item>
                )}

                <Form.Item
                  label={
                    <span>
                      <span>Email:</span>{' '}
                      <Tooltip title="Please enter your correct LinkedIn login email and password.">
                        <Icon type="info-circle" className="tooltip-icon" style={{marginRight: 5}} />
                      </Tooltip>
                      {isAdmin && <AdminFeatureIcon />}
                    </span>
                  }
                >
                  <Input
                    value={values.email}
                    name="email"
                    className={errors.email && touched.email ? 'invalid-input' : ''}
                    disabled={isUpdateUser && !isAdmin}
                    autoComplete="client-user-email"
                    onChange={e => this.handleChangeEmail(e.target.value, setFieldValue)}
                    onBlur={handleBlur}
                    placeholder="Type your user linkedin email used for logging in on LinkedIn"
                  />
                </Form.Item>
                <ErrorMessage name="email" className="error-message" component="div" />

                {(!this.props.client.allow_quick_user_creation || isAdmin) && (
                  <Form.Item label={<span>Password*: {isAdmin && <AdminFeatureIcon />}</span>}>
                    <Input type="password" style={{display: 'none'}} />{' '}
                    {/* 'hack for preventing browser save password popup' */}
                    <Input
                      type="password"
                      autoComplete="new-password"
                      value={values.password}
                      name="password"
                      className={errors.password && touched.password ? 'invalid-input' : ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={
                        this.props.user.id
                          ? '*******************'
                          : 'Type your user LinkedIn password used for logging in'
                      }
                      addonAfter={
                        isAdmin ? <Icon style={{cursor: 'pointer'}} onClick={this.revealPassword} type="copy" /> : null
                      }
                    />
                  </Form.Item>
                )}

                <ErrorMessage name="password" className="error-message" component="div" />

                {this.props.client.linkedin_basic_feature && (
                  <SwitchRow
                    label={<span>Sales Navigator subscription:</span>}
                    onChange={(checked: boolean) => setFieldValue('basic_linkedin_sub', !checked)}
                    checked={!values.basic_linkedin_sub}
                    isLock={false}
                  />
                )}
                {values.basic_linkedin_sub && (
                  <Alert
                    style={{
                      marginBottom: 10,
                    }}
                    message={STRINGS.warning.basic_linkedin_user_warning}
                    type="warning"
                  />
                )}

                <Form.Item
                  className="user-edit-modal__slider-form-item"
                  labelCol={{span: 13}}
                  wrapperCol={{span: 11}}
                  label={
                    <span>
                      <span>Daily Invites Limit (acquisition):</span>{' '}
                      <Tooltip
                        title={
                          <span>
                            Be aware that the daily invites limit only applies to normal acquisition campaigns. For more
                            information about limits for Acquisition+,{' '}
                            {(brandConfig.isGx || brandConfig.isLeadoku) && (
                              <a
                                style={{textDecoration: 'underline'}}
                                rel="noreferrer"
                                href={brandConfig.articles.acquisitionCampaignEmail}
                                target="_blank"
                              >
                                click here.
                              </a>
                            )}
                          </span>
                        }
                      >
                        <Icon type="info-circle" className="tooltip-icon" />
                      </Tooltip>
                    </span>
                  }
                >
                  <div className="slider-wrapper">
                    <span>0</span>
                    <Slider
                      min={0}
                      max={this.props.user.max_messages}
                      step={1}
                      defaultValue={50}
                      value={values.max_messages_limit}
                      onChange={val => setFieldValue('max_messages_limit', val)}
                    />
                    <span>{this.props.user.max_messages}</span>
                  </div>
                </Form.Item>
                <ErrorMessage name="max_messages_limit" className="error-message" component="div" />

                <Form.Item
                  className="user-edit-modal__slider-form-item"
                  labelCol={{span: 13}}
                  wrapperCol={{span: 11}}
                  label={
                    <span>
                      <span>Weekly Invites Limit (acquisition):</span>{' '}
                      <Tooltip
                        title="The maximum number of weekly invites allowed by LinkedIn is 200. If you are sending any
                          invites manually during the week with this user, make sure to reduce this number. For example
                          if you send about 10 invites manually every week, makes sure to reduce this number to 190."
                      >
                        <Icon type="info-circle" className="tooltip-icon" />
                      </Tooltip>
                    </span>
                  }
                >
                  <div className="slider-wrapper">
                    <span>0</span>
                    <Slider
                      min={0}
                      max={200}
                      step={1}
                      defaultValue={180}
                      value={values.weekly_max_messages_limit}
                      onChange={val => setFieldValue('weekly_max_messages_limit', val)}
                    />
                    <span>200</span>
                  </div>
                </Form.Item>

                {this.props.client.import_emails_feature && (
                  <Form.Item
                    className="user-edit-modal__slider-form-item"
                    labelCol={{span: 13}}
                    wrapperCol={{span: 11}}
                    label={
                      <span>
                        <span>Weekly Invites Limit (acq+):</span>{' '}
                        <Tooltip title="This value defines the maximum number of weekly invites sent with Acquisition+ campaigns only.">
                          <Icon type="info-circle" className="tooltip-icon" />
                        </Tooltip>
                      </span>
                    }
                  >
                    <div className="slider-wrapper">
                      <span>0</span>
                      <Slider
                        min={0}
                        max={this.props.user.weekly_invites_via_email_upper_limit}
                        step={1}
                        defaultValue={50}
                        value={values.weekly_invites_limit_via_email}
                        onChange={val => setFieldValue('weekly_invites_limit_via_email', val)}
                      />
                      <span>{this.props.user.weekly_invites_via_email_upper_limit}</span>
                    </div>
                  </Form.Item>
                )}

                {!!this.showNurturingLimit(this.props.client) && (
                  <Form.Item
                    className="user-edit-modal__slider-form-item"
                    labelCol={{span: 13}}
                    wrapperCol={{span: 11}}
                    label={
                      <span>
                        <span>Daily Messages Limit (nurturing):</span>{' '}
                        <Tooltip
                          title="This limit defines the maximum number of first nurturing messages sent by nurturing
                          campaigns. This limit doesn't take in consideration the followups sent by nurturing
                          campaigns."
                        >
                          <Icon type="info-circle" className="tooltip-icon" />
                        </Tooltip>
                      </span>
                    }
                  >
                    <div className="slider-wrapper">
                      <span>0</span>
                      <Slider
                        min={0}
                        max={100}
                        step={1}
                        defaultValue={100}
                        value={values.max_nurturing_message_limit}
                        onChange={val => setFieldValue('max_nurturing_message_limit', val)}
                      />
                      <span>100</span>
                    </div>
                  </Form.Item>
                )}

                <Form.Item
                  labelCol={{span: 16}}
                  wrapperCol={{span: 8}}
                  label={
                    <span>
                      <span>Increase daily invites limit automatically:</span>{' '}
                      <Tooltip title="Daily invite limit will automatically increase gradually following our own internal rules to keep your account safe.">
                        <Icon type="info-circle" className="tooltip-icon" />
                      </Tooltip>
                    </span>
                  }
                >
                  <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                    {!isUpdateUser ? (
                      <div style={{marginRight: 10}}>
                        <LockIcon message={STRINGS.info_user_pending_invites_lock} />
                      </div>
                    ) : null}
                    <Switch
                      style={{float: 'right'}}
                      disabled={!isUpdateUser}
                      onChange={val => setFieldValue('auto_update_messages_limits', val)}
                      checked={!!values.auto_update_messages_limits}
                    />
                  </div>
                </Form.Item>

                <Form.Item
                  labelCol={{span: 12}}
                  wrapperCol={{span: 12}}
                  label={
                    <span>
                      <span>Delete Pending Invites:</span>{' '}
                      <Tooltip title="By checking this, any pending invite older than 30 days will be deleted, this is recommended to keep the user in good standing.">
                        <Icon type="info-circle" className="tooltip-icon" />
                      </Tooltip>
                    </span>
                  }
                >
                  <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                    <div style={{marginRight: 10}}>
                      <LockIcon message={STRINGS.info_user_pending_invites_lock} />
                    </div>
                    <Switch
                      style={{float: 'right'}}
                      disabled={true}
                      onChange={val => setFieldValue('delete_pending_invites', val)}
                      checked={!!values.delete_pending_invites}
                    />
                  </div>
                </Form.Item>
                <Form.Item
                  labelCol={{span: 16}}
                  wrapperCol={{span: 8}}
                  label={
                    <span>
                      <span>Twitter Integration:</span>{' '}
                      <Tooltip title={STRINGS.tooltip.twitter_connect}>
                        <Icon type="info-circle" className="tooltip-icon" />
                      </Tooltip>
                    </span>
                  }
                >
                  <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                    {!this.props.client.twitter_feature && (
                      <div style={{marginRight: 10}}>
                        <LockIcon
                          onClick={() =>
                            this.props.trackIntercomEvent(
                              'twitter_feature_request',
                              true,
                              !this.props.client.twitter_feature
                            )
                          }
                          message={STRINGS.message_unlock_feature}
                        />
                      </div>
                    )}
                    <Button
                      size="small"
                      disabled={!this.props.client.twitter_feature}
                      type={twitter_connected ? 'default' : 'primary'}
                      onClick={() => {
                        this.props.handleTwitter(this.props.user.id, !twitter_connected);
                      }}
                    >
                      {twitter_connected ? 'Disconnect' : 'Connect'}
                    </Button>
                  </div>
                </Form.Item>
                <SwitchRow
                  label={
                    <span>
                      Allow other users to contact this user’s connections and prospects:{' '}
                      <InfoIcon filled={false} className="tooltip-icon" message={STRINGS.tooltip.exclude_user} />
                    </span>
                  }
                  onChange={(checked: boolean) => setFieldValue('ignore_actions_with_receivers_from_user', checked)}
                  checked={!!values.ignore_actions_with_receivers_from_user}
                  isLock={false}
                />
                {this.props.isNewUser && (
                  <SwitchRow
                    checked={values.create_team_member}
                    label={
                      <span>
                        Send invite to access {brandConfig.name}:{' '}
                        <InfoIcon
                          filled={false}
                          className="tooltip-icon"
                          message={STRINGS.tooltip.create_team_member}
                        />
                      </span>
                    }
                    onChange={(checked: boolean) => setFieldValue('create_team_member', checked)}
                    isLock={this.isLockCreateTeamMember(values.email)}
                  />
                )}

                {this.props.user.multiple_sn && (
                  <Form.Item
                    labelCol={{span: 12}}
                    wrapperCol={{span: 12}}
                    label={
                      <span>
                        <span>Sales Navigator Subscription:</span>{' '}
                        <Tooltip title={STRINGS.info_user_sales_navigator}>
                          <Icon type="info-circle" className="tooltip-icon" />
                        </Tooltip>
                      </span>
                    }
                  >
                    <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                      <Select
                        style={{width: 230}}
                        placeholder="Subscription"
                        defaultValue={1}
                        onChange={(value: any) => setFieldValue('sn_account_selection', value)}
                        value={values.sn_account_selection}
                        getPopupContainer={trigger => trigger.parentElement}
                      >
                        {snSubsctiptionOptions.map(subsciption => (
                          <Select.Option key={`${subsciption.value}`} value={subsciption.value}>
                            {`${subsciption.label} Subscription`}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                  </Form.Item>
                )}
                {this.props.error && (
                  <Row style={{marginBottom: '10px'}}>
                    <Alert message={this.props.error} type="error" />
                  </Row>
                )}
                {isAdmin && (
                  <>
                    <Divider className="user-edit-modal-divider"></Divider>
                    <Form.Item
                      label={
                        <span>
                          Cookies: <AdminFeatureIcon />
                        </span>
                      }
                    >
                      <Button
                        disabled={!this.props.user.id || !values.cookies}
                        onClick={() => {
                          this.props.clearCookies(this.props.user.id);
                          setFieldValue('cookies', null);
                        }}
                      >
                        Clear cookies
                      </Button>
                    </Form.Item>
                    <Form.Item
                      label={
                        <span>
                          Disable/Enable user: <AdminFeatureIcon />
                        </span>
                      }
                    >
                      <Button
                        onClick={() => {
                          const newState = !values.disabled;
                          this.props.changeUserState(this.props.user.id, newState);
                          setFieldValue('disabled', newState);
                        }}
                      >
                        {values.disabled ? 'Enable' : 'Disable'}
                      </Button>
                    </Form.Item>
                    <Form.Item
                      label={
                        <span>
                          Last login error: <AdminFeatureIcon />
                        </span>
                      }
                    >
                      <span style={{marginRight: 5}}>
                        {values.last_login_error_date
                          ? moment(values.last_login_error_date).format('MM/DD/YYYY HH:mm')
                          : 'None'}
                      </span>
                      <Button
                        disabled={!this.props.user.id || !values.last_login_error_date}
                        onClick={() => {
                          this.props.resetLoginErrorDate(this.props.user.id);
                          setFieldValue('last_login_error_date', null);
                        }}
                      >
                        Reset
                      </Button>
                    </Form.Item>
                    <div>
                      Snooze login error pulse for this user for{' '}
                      <Select
                        style={{width: 60, marginRight: 5}}
                        value={this.state.login_error_snooze_days}
                        onChange={value => this.setState({login_error_snooze_days: value})}
                        getPopupContainer={trigger => trigger.parentElement}
                      >
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(day => (
                          <Select.Option key={day} value={day}>
                            {day}
                          </Select.Option>
                        ))}
                      </Select>
                      day{this.state.login_error_snooze_days > 1 && 's'}
                      <Button type="primary" style={{marginLeft: 5, marginRight: 5}} onClick={this.snoozeLoginError}>
                        Snooze
                      </Button>
                      {<AdminFeatureIcon />}
                    </div>
                    {this.props.client.proxy_feature && (
                      <UserProxy
                        errors={errors}
                        touched={touched}
                        values={values}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        isAdmin={isAdmin}
                        user={this.props.user}
                        handleLocationChange={this.handleLocationChange}
                        sortedLocations={this.state.sortedLocations}
                        customProxyVisible={this.props.customProxyVisible}
                        onSwitchCheckedChange={this.onSwitchCheckedChange}
                        removeUserCustomProxy={this.props.removeUserCustomProxy}
                        replaceUserProxy={this.props.replaceUserProxy}
                      />
                    )}
                    <Row type="flex" gutter={5} justify="space-between">
                      <Col span={13}>
                        <Form.Item
                          label={
                            <span>
                              Move user to other client as: <AdminFeatureIcon />
                            </span>
                          }
                        >
                          <Tooltip
                            overlayStyle={{maxWidth: '100%'}}
                            visible={this.props.client.enabled}
                            title="Please stop campaigns for executing this action"
                          >
                            <Select
                              disabled={this.props.client.enabled}
                              showSearch
                              placeholder="Select a client"
                              value={this.state.selectedClient}
                              onChange={selectedClient => this.setState({selectedClient})}
                              getPopupContainer={trigger => trigger.parentElement}
                            >
                              {affiliatedClients?.map(c => (
                                <Select.Option key={c?.id} value={c?.id}>{`${c?.email} (${c?.id})`}</Select.Option>
                              ))}
                            </Select>
                          </Tooltip>
                        </Form.Item>
                      </Col>
                      <Col className="user-edit-modal__move-with-campaigns-switch" span={11}>
                        <Form.Item
                          className="user-edit-modal__move-with-campaigns-form-item"
                          labelCol={{span: 20}}
                          wrapperCol={{span: 4}}
                          label={'Move with campaigns'}
                        >
                          <Switch
                            style={{float: 'right'}}
                            disabled={this.props.client.enabled}
                            onChange={moveUserWithCampaigns => this.setState({moveUserWithCampaigns})}
                            checked={this.state.moveUserWithCampaigns}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )}
              </div>

              <div style={{padding: '0 20px 20px 20px'}}>
                {isFirstUserForClient && (
                  <Alert
                    className="user-requirements__alert"
                    message={
                      <span>
                        {`Please read `}
                        <b>
                          {isMobile ? (
                            <UserRequirementsPopover>
                              <Button type="link" className="user-requirements__button-link">
                                LinkedIn User Recommendations
                              </Button>
                            </UserRequirementsPopover>
                          ) : (
                            <UserRequirementsTooltip>
                              <Button type="link" className="user-requirements__button-link">
                                LinkedIn User Recommendations
                              </Button>
                            </UserRequirementsTooltip>
                          )}
                        </b>
                      </span>
                    }
                    type="info"
                    style={{marginBottom: 20}}
                  />
                )}
                <Row className="user-edit-modal__buttons-row" type="flex">
                  <Button onClick={dirty ? this.onClose : this.onCloseModals}>Cancel</Button>
                  <Button
                    className="user-edit-modal__submit-button"
                    loading={this.props.loading}
                    disabled={!!this.props.user.archived}
                    type="primary"
                    htmlType="submit"
                  >
                    {this.getSubmitTextButton(values.first_name, values.password)}
                  </Button>
                </Row>
                {!brandConfig.isAffiliate && (
                  <Row className="user-edit-modal__terms-row">
                    <span>By clicking save you agree to our</span>{' '}
                    <a href={`https://growth-x.com/terms`} target="_blank" rel="noreferrer noopener">
                      Terms of Use
                    </a>{' '}
                    <span>and</span>{' '}
                    <a href={`https://growth-x.com/privacy`} target="_blank" rel="noreferrer noopener">
                      Privacy Policy
                    </a>
                  </Row>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}
