import {Button, Divider, Empty, Icon, Layout, Tooltip} from 'antd';
import moment from 'moment';
import React, {useCallback, useMemo} from 'react';

import {findIconDefinition} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Client, Template, User} from '@growth-x/types';
import {IconWrapper} from '@growth-x/ui';

import {brandConfig, CONSTANTS, InfoIcon, STRINGS} from '../..';
import userImage from '../../assets/images/user.png';
import {SendMessage} from './conversation/SendMessage';
import {ConversationMessage} from './conversationMessage';
import {SnoozeConversationButton} from './snoozeConversationButton';
import './inboxConversation.css';

const {Header, Content, Footer} = Layout;

interface ConversationProps {
  conversation: any;
  user: User;
  client: Client;
  team_member: any;
  templates: Template[];
  serverState?: string;
  handleSendMessage: (params: any) => void;
  updateMessageStatus: (params: any) => void;
  unmarkConversationYourTurn: (params: any) => void;
  getCampaignById: (campaignId: number) => any;
  snoozeConversation: (id: number, date: string) => any;
  archiveConversation: (id: number, flag: boolean) => any;
  setInSequenceConversation: (id: number, state: string) => any;
  createClientAttachment: (attachment: any) => any;
  deleteClientAttachment: (attachment_id: any) => void;
  getPreview: (urls: string[]) => any[];
  clientAttachments: any;
  addCustomEmailToUserReceiver: (params: any) => any;
  addCustomPhoneToUserReceiver: (params: any) => any;
  createConversationNote: (params: any) => void;
  members: any[];
  csmList: any[];
  setHeaderModal: (modal: string) => void;
  suggestTemplate: (conversationId: number) => any;
  moveFromPromoPlan: () => void;
  saveSuggestionFeedback: (conversationId: number, score: number, commentary: string) => any;
}

function getSequenceButtonTooltip(status) {
  if (['STOPPED_OUT_OF_SYNC', 'FINISHED'].includes(status)) {
    return 'Campaign Sequence Finished';
  } else if (status === 'CANCELLED') {
    return 'Campaign Sequence Canceled';
  } else if (status === 'RUNNING') {
    return 'Stop Campaign Sequence';
  }
  return 'Resume Campaign Sequence';
}

export function InboxConversation({
  templates,
  addCustomPhoneToUserReceiver,
  addCustomEmailToUserReceiver,
  clientAttachments,
  getCampaignById,
  createClientAttachment,
  updateMessageStatus,
  client,
  team_member,
  user,
  deleteClientAttachment,
  conversation,
  archiveConversation,
  setInSequenceConversation,
  snoozeConversation,
  handleSendMessage,
  unmarkConversationYourTurn,
  createConversationNote,
  members,
  csmList,
  suggestTemplate,
  saveSuggestionFeedback,
  serverState,
  setHeaderModal,
  getPreview,
  moveFromPromoPlan,
}: Readonly<ConversationProps>) {
  if (!conversation) {
    return (
      <div className="receivers-list-empty">
        <Empty description="There are no search results for this user" />
      </div>
    );
  }
  const isInvalidConversation = (conversation && conversation.ur_invalid === 6) || (user && user.archived);
  const toggleArchived = async () => {
    await archiveConversation(conversation.id, !conversation.archived);
  };
  const snoozeAndRefresh = async (id, date) => {
    await snoozeConversation(id, date);
  };

  const filterDuplicates = (source, compare) => {
    const timecodes = compare.map(m => m.sent_date);
    return source.filter(m => !timecodes.includes(m.sent_date));
  };

  const sortMessages = (a: any, b: any) => {
    if (a.creation_date) {
      return new Date(a.creation_date).getTime() - new Date(b.creation_date).getTime();
    }
    return new Date(a.sent_date).getTime() - new Date(b.sent_date).getTime();
  };

  const profileImage = conversation?.receiver?.photo_url || userImage;

  const getEventContent = useCallback(
    (msg, type) => {
      const name = msg?.initiator?.name || '';
      const map = {
        snoozed_by_client: {
          message: `${name || client?.name} snoozed the conversation`,
        },
        unsnoozed_by_client: {
          message: `${name || client?.name} re-opened the conversation`,
        },
        auto_unsnoozed: {
          message: 'Operator unsnoozed the conversation automatically',
        },
        auto_unsnoozed_new_response: {
          message: `${name} replied and re-opened the conversation. Messages received in LinkedIn will appear within 24 hours.`,
          tooltip: brandConfig.isAffiliate ? '' : `Check this article ${brandConfig.articles.botCycle} to see how to rescan messages manually.`,
        },
        auto_unarchived_new_message: {
          message: `${name} replied and re-opened the conversation. Messages received in LinkedIn will appear within 24 hours.`,
          tooltip: brandConfig.isAffiliate ? '' : `Check this article ${brandConfig.articles.botCycle} to see how to rescan messages manually.`,
        },
        archived_by_client: {
          message: `${name || client?.name} closed the conversation`,
        },
        unarchived_by_client: {
          message: `${name || client?.name} opened the conversation`,
        },
        default: {
          message: 'event log',
        },
      };
      const content = (
        <span>
          {`${map[type]?.message || map['default']?.message}`}{' '}
          {map[type]?.tooltip ? (
            <InfoIcon message={map[type]?.tooltip} />
          ) : (
            `${moment(msg.sent_date).format(CONSTANTS.main_date_format)}`
          )}
        </span>
      );

      return content;
    },
    [client]
  );

  const backupMessages = useMemo(() => {
    if (!conversation.backup_messages?.length) {
      return [];
    }

    return filterDuplicates(
      conversation.backup_messages.filter(message => !message.sent_before_gx),
      conversation.messages
    ).sort(sortMessages);
  }, [conversation]);

  const allMessages = useMemo(() => {
    const events = conversation.events.map(m => {
      const msg = {...m};
      delete msg['creation_date'];
      msg['sent_date'] = m.creation_date;
      msg['kind'] = 'event';
      msg['content'] = getEventContent(msg, m.type);
      return msg;
    });
    const notes = conversation.notes
      ? conversation.notes?.map(n => {
          const note = {...n};
          note.kind = 'note';
          note.content = n.note;
          return note;
        })
      : [];
    const regularMessages = conversation.messages.filter(message => !message.sent_before_gx);

    return [...regularMessages, ...events, ...notes].sort(sortMessages);
  }, [conversation, getEventContent]);

  const scheduledMessages = useMemo(() => conversation.scheduled_messages.sort(sortMessages), [conversation]);

  const connectionMessage = {
    kind: 'meta',
    content:
      conversation.connected_via_acquisition && conversation.campaign_name ? (
        <span>
          {`Receiver was connected ${moment(conversation.connection_date).startOf('day').format('DD MMMM YYYY')} via `}
          <span style={{fontStyle: 'italic'}}>{conversation.campaign_name}</span>
        </span>
      ) : (
        `Receiver connected`
      ),
    sent_date: moment(conversation.connection_date).startOf('day'),
  };

  const toggleSetInSequence = async () => {
    await setInSequenceConversation(conversation.id, conversation.sequence_state === 'RUNNING' ? 'STOPPED' : 'RUNNING');
  };

  return (
    <div className="conversation-container">
      <Header className="conversation-header">
        <div className="conversation_header_wrapper">
          <div className="conversation__user">
            <img className="conversation__image" src={profileImage} alt="user" />
            <div className="conversation__info">
              <span>
                {conversation.receiver.linkedin_url ? (
                  <a
                    className="conversation__receiver-info"
                    target="_blank"
                    rel="noreferrer"
                    href={conversation.receiver.linkedin_url}
                  >{`${conversation.receiver.first_name} ${conversation.receiver.last_name}`}</a>
                ) : (
                  <span className="conversation__receiver-info">{`${conversation.receiver.first_name} ${conversation.receiver.last_name}`}</span>
                )}
                <InfoIcon message={conversation.receiver.headline} />
              </span>
              {conversation?.receiver?.company_profile_id && conversation?.receiver?.company ? (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${STRINGS.company_url + conversation.receiver.company_profile_id}`}
                >
                  {conversation.receiver.company}{' '}
                  <FontAwesomeIcon icon={findIconDefinition({prefix: 'fas', iconName: 'external-link-alt'})} />
                </a>
              ) : (
                <span>{conversation.receiver.company}</span>
              )}
            </div>
          </div>
          <div className="conversation__actions">
            <SnoozeConversationButton
              conversation={conversation}
              snoozeConversation={snoozeAndRefresh}
              toggleArchived={toggleArchived}
            />
            <Tooltip title={conversation.archived ? 'Reopen' : 'Close'}>
              <Button
                className={`archive_button${conversation.archived ? ' enabled' : ''}`}
                onClick={toggleArchived}
                style={{marginLeft: 5}}
                type="dashed"
              >
                <IconWrapper>
                  <FontAwesomeIcon
                    icon={findIconDefinition({
                      prefix: 'fas',
                      iconName: 'archive',
                    })}
                  />
                </IconWrapper>
              </Button>
            </Tooltip>
            {client.sequence_feature && (
              <Tooltip title={getSequenceButtonTooltip(conversation.sequence_state)}>
                <Button
                  onClick={toggleSetInSequence}
                  style={{marginLeft: 5}}
                  disabled={['STOPPED_OUT_OF_SYNC', 'FINISHED', 'CANCELLED'].includes(conversation.sequence_state)}
                  type="dashed"
                >
                  <IconWrapper>
                    <FontAwesomeIcon
                      icon={findIconDefinition({
                        prefix: 'fas',
                        iconName: conversation.sequence_state === 'RUNNING' ? 'pause' : 'play',
                      })}
                    />
                  </IconWrapper>
                </Button>
              </Tooltip>
            )}
          </div>
        </div>
      </Header>

      <Content className={`conversation ${isInvalidConversation ? 'invalid' : ''}`}>
        <div className="conversation_wrapper">
          <span className="conversation__updated">
            Updated: {moment(conversation.update_date).format(CONSTANTS.main_date_format)}
          </span>
          <ConversationMessage
            key="connectionMessage"
            user={user}
            client={client}
            members={members}
            message={connectionMessage}
            updateMessageStatus={updateMessageStatus}
            trackingLinks={conversation.tracking_links}
            addCustomEmailToUserReceiver={addCustomEmailToUserReceiver}
            addCustomPhoneToUserReceiver={addCustomPhoneToUserReceiver}
            userReceiverId={conversation.id}
            getPreview={getPreview}
            serverState={serverState}
          />
          {backupMessages.map((message, index) => (
            <ConversationMessage
              key={`backupMessages-${index}`}
              user={user}
              client={client}
              members={members}
              message={message}
              updateMessageStatus={updateMessageStatus}
              trackingLinks={conversation.tracking_links}
              addCustomEmailToUserReceiver={addCustomEmailToUserReceiver}
              addCustomPhoneToUserReceiver={addCustomPhoneToUserReceiver}
              userReceiverId={conversation.id}
              getPreview={getPreview}
              serverState={serverState}
            />
          ))}
          {!!conversation.backup_messages?.length && (
            <Divider orientation="left">
              {'Messages above are restored from backup '}
              <InfoIcon message={STRINGS.tooltip.backup_messages} />
            </Divider>
          )}
          {allMessages.map((message, index) => (
            <ConversationMessage
              key={`allMessages-${index}`}
              user={user}
              client={client}
              members={members}
              message={message}
              updateMessageStatus={updateMessageStatus}
              trackingLinks={conversation.tracking_links}
              addCustomEmailToUserReceiver={addCustomEmailToUserReceiver}
              addCustomPhoneToUserReceiver={addCustomPhoneToUserReceiver}
              userReceiverId={conversation.id}
              getPreview={getPreview}
              serverState={serverState}
            />
          ))}
          {scheduledMessages.map(message => (
            <ConversationMessage
              key={`${message.id}`}
              message={message}
              user={user}
              client={client}
              members={members}
              updateMessageStatus={updateMessageStatus}
              addCustomEmailToUserReceiver={addCustomEmailToUserReceiver}
              addCustomPhoneToUserReceiver={addCustomPhoneToUserReceiver}
              userReceiverId={conversation.id}
              getPreview={getPreview}
              serverState={serverState}
            />
          ))}
          {conversation.snoozed_until && (
            <div className="conversation__snooze-info">
              You snoozed this conversation until {moment(conversation.snoozed_until).format('D MMMM ha')}
            </div>
          )}
          {!!isInvalidConversation && (
            <div className="conversation__invalid-text">
              {!user?.archived
                ? 'Message cannot be sent because you are not connected to this receiver'
                : 'Message cannot be sent because the user is archived'}
            </div>
          )}
        </div>
      </Content>
      <Footer className="conversation__footer">
        <SendMessage
          conversation={conversation}
          user={user}
          templates={templates}
          getCampaignById={getCampaignById}
          createClientAttachment={createClientAttachment}
          clientAttachments={clientAttachments}
          deleteClientAttachment={deleteClientAttachment}
          isInvalidConversation={isInvalidConversation}
          handleSendMessage={handleSendMessage}
          toggleArchived={toggleArchived}
          unmarkConversationYourTurn={unmarkConversationYourTurn}
          createConversationNote={createConversationNote}
          members={members}
          csmList={csmList}
          client={client}
          team_member={team_member}
          suggestTemplate={suggestTemplate}
          saveSuggestionFeedback={saveSuggestionFeedback}
          setHeaderModal={setHeaderModal}
          moveFromPromoPlan={moveFromPromoPlan}
        />
      </Footer>
    </div>
  );
}
