import {Modal, Button, Select, Row, Col, Collapse, Icon, Spin, Progress} from 'antd';
import React, {useEffect, useState, useRef} from 'react';

import {AdminFeatureIcon} from '@growth-x/ui';

import {CreateCloudInstance} from './CreateCloudInstance';
import './ManageCloudInstance.scss';

const {Option} = Select;
const {confirm} = Modal;

const DELAY_DAYS = [...Array(31).keys()];

export function ManageCloudInstance({
  clientInstance,
  createCloudInstance,
  forceRebootCloudInstance,
  delayDeleteCloudInstance,
  deleteCloudInstance,
  getCloudInstance,
  getLoading,
  updateLoading,
  setProgressPercentage,
  setProgressDone,
  getClient,
  getClientServerState,
  serverState,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const [postponeDays, setPostponeDays] = useState(5);
  const intervalRef = useRef(null);

  const handleForceReboot = () => {
    forceRebootCloudInstance();
  };

  const handlePostponeDelete = () => {
    confirm({
      title: 'Are you sure?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        if (postponeDays) {
          delayDeleteCloudInstance(postponeDays);
        } else {
          deleteCloudInstance();
        }
      },
    });
  };

  useEffect(() => {
    getCloudInstance();
    return () => {
      // Clear the interval when the component is unmounted
      clearInterval(intervalRef.current);
    };
  }, []);

  const nextPercentage = (clientInstance?.task && clientInstance?.task?.next_percentage) || 0;
  const percentage = (clientInstance?.task && clientInstance?.task?.percentage) || 0;
  const message = clientInstance?.task && clientInstance?.task?.message;
  const state = clientInstance?.task && clientInstance?.task?.state;
  const action = clientInstance?.task && clientInstance?.task?.action;

  // Clear previous interval
  if (intervalRef.current) {
    clearInterval(intervalRef.current);
  }

  const AVG_TIME = 4 * 60;
  const average_time_needed_for_stage = AVG_TIME * (nextPercentage - percentage);
  const FPS = 24;

  // Smoothly transition from current percentage to next percentage
  intervalRef.current = setInterval(() => {
    const increment = parseFloat(
      (((nextPercentage - percentage) * 1000) / average_time_needed_for_stage / FPS).toFixed(2)
    );

    if (action !== 'retry' && (percentage > nextPercentage || !nextPercentage || state === 'SUCCESS' || serverState === 'running')) {
      // Clear interval when transition is complete
      setProgressDone();
      if (state === 'SUCCESS') {
        getCloudInstance();
        getClient();
        getClientServerState();
      }
      clearInterval(intervalRef.current);
    } else {
      setProgressPercentage(parseFloat((percentage + increment).toFixed(2)));
    }
  }, 2500);

  const isReady = clientInstance && clientInstance?.password;

  return (
    <div>
      {message && state !== 'SUCCESS' && (
        <div style={{padding: 10}}>
          <span>{message}...</span>
          <Progress status={'active'} percent={percentage} />
        </div>
      )}

      <Spin spinning={getLoading || updateLoading || state === 'FAILURE'}>
        {!isReady && !getLoading && (
          <div style={{marginBottom: 15}}>
            <CreateCloudInstance onSubmit={createCloudInstance} loading={updateLoading} />
          </div>
        )}
        {isReady && !getLoading && (
          <div>
            <Collapse className="cloud_instance">
              <Collapse.Panel
                header={
                  <b>
                    <AdminFeatureIcon /> Cloud Instance:
                  </b>
                }
                key="1"
              >
                <Row type="flex" align="middle" gutter={[16, 16]}>
                  <Col span={4} key={'Username'}>
                    <b>Username:</b>
                  </Col>
                  <Col span={20}>{clientInstance.username}</Col>
                  <Col span={4} key={'Password'}>
                    <b>Password:</b>
                  </Col>
                  <Col span={20} key={'Password-Icon'}>
                    {showPassword ? clientInstance.password : '********'}{' '}
                    <Icon onClick={() => setShowPassword(v => !v)} type={showPassword ? 'eye' : 'eye-invisible'} />
                  </Col>
                  <Col span={4} key={'Server-Area-label'}>
                    <b>Server area:</b>
                  </Col>
                  <Col span={20} key={'Server-Area'}>
                    {clientInstance.server_area}
                  </Col>
                  <Col span={4} key={'Actions-label'}>
                    <b>Actions:</b>
                  </Col>
                  <Col span={20} key={'Actions'}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <Button
                        style={{
                          marginRight: 30,
                          color: 'var(--color-main-blue)',
                          border: '1px solid var(--color-main-blue)',
                        }}
                        loading={updateLoading}
                        disabled={updateLoading}
                        onClick={handleForceReboot}
                      >
                        Reboot
                      </Button>
                      <Button
                        loading={updateLoading}
                        disabled={updateLoading}
                        style={{marginRight: 5}}
                        type="danger"
                        onClick={handlePostponeDelete}
                      >
                        Delete {postponeDays ? 'in' : '  '}
                      </Button>
                      <Select
                        disabled={updateLoading}
                        value={postponeDays}
                        style={{width: 100}}
                        onChange={day => setPostponeDays(day)}
                      >
                        {DELAY_DAYS.map(day => (
                          <Option value={day} key={day}>
                            {day ? `${day} days` : 'now'}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </Col>
                </Row>
              </Collapse.Panel>
            </Collapse>
          </div>
        )}
      </Spin>
    </div>
  );
}
