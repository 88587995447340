import {connect} from 'react-redux';

import {Logo} from '@growth-x/ui';

import {chargebeeActions} from '../../redux/chargebee';
import {headerModalActions} from '../../redux/headerModal';
import {IntercomService, InternalAnalyticsService} from '../../services';

function mapState(state: any) {
  return {
    client: state.client.data,
    isAdmin: state.settings.isAdmin,
    openBilling: () => headerModalActions.setHeaderModal('subscription'),
    onUpgrade: () => IntercomService.trackIntercomEvent('upgrade_request', true, !state.client.data.upgrade_request),
    onDemoSubmit: email =>
      InternalAnalyticsService.sendEvent({event_type: 'demo_scheduled', code: '', hubspot_email: email}),
  };
}

const actionCreators = {
  activateRegularPlan: chargebeeActions.activateRegularPlan,
};

export const LogoContainer = connect(mapState, actionCreators)(Logo);
