export class BillingService {
  client: any;
  constructor({client}: any) {
    this.client = client;
  }
  serviceName = 'billing';
  getPortalSession() {
    return this.client.post(`${this.serviceName}/portal_session`, {});
  }
  getSubscriptionInfo() {
    return this.client.get(`${this.serviceName}/subscription`, {});
  }
  updateSubscription(params) {
    return this.client.post(`${this.serviceName}/manage_subscription`, params);
  }
  updateSubscriptionLeadoku(params) {
    return this.client.post(`${this.serviceName}/manage_leadoku_subscription`, params);
  }
  endTrial() {
    return this.client.post(`${this.serviceName}/end_trial`, {});
  }
  cancelSubscription(params) {
    return this.client.post(`${this.serviceName}/cancel`, {
      ...(params?.reason && {reason: params.reason}),
    });
  }
  removeScheduledCancelation() {
    return this.client.post(`${this.serviceName}/remove_scheduled_cancellation`, {});
  }
  putOnHold(params) {
    return this.client.post(`${this.serviceName}/on_hold`, params);
  }
  removeScheduledOnHold() {
    return this.client.post(`${this.serviceName}/remove_scheduled_on_hold`, {});
  }
  removeCoupon(params) {
    return this.client.post(`${this.serviceName}/remove_coupon`, params);
  }

  getBillingContactEmails() {
    return this.client.get(`${this.serviceName}/billing_contacts`, {});
  }

  createBillingContactEmail(email) {
    const params = {
      section: 'contacts',
      action: 'create',
      email,
    };
    return this.client.post(`${this.serviceName}/billing_contacts`, params);
  }

  updateBillingContactEmail({email, contact_id}) {
    const params = {
      section: 'contacts',
      action: 'update',
      email,
      contact_id,
    };
    return this.client.post(`${this.serviceName}/billing_contacts`, params);
  }

  deleteBillingContactEmail(contact_id) {
    const params = {
      section: 'contacts',
      action: 'delete',
      contact_id,
    };
    return this.client.post(`${this.serviceName}/billing_contacts`, params);
  }

  upsertBillingContactEmail(email) {
    const params = {
      section: 'billing_info',
      action: 'update',
      email,
    };
    return this.client.post(`${this.serviceName}/billing_contacts`, params);
  }

  purchaseLeadokuPromo(token) {
    return this.client.post(`${this.serviceName}/by_token/purchase_leadoku_promo`, {token});
  }

  moveFromPromoPlan() {
    return this.client.post(`${this.serviceName}/move_from_promo_plan`, {});
  }

  verifyCancellation() {
    return this.client.get(`${this.serviceName}/verify_cancellation`, {});
  }

  leadokuReactivate(params) {
    return this.client.post(`${this.serviceName}/wl_reactivate`, params);
  }

  removeScheduledChanges() {
    return this.client.post(`${this.serviceName}/remove_scheduled_changes`, {});
  }

  startTrial() {
    return this.client.post(`${this.serviceName}/start_trial`, {});
  }
  activateRegularPlan(period: string) {
    return this.client.post(`${this.serviceName}/activate_regular_plan`, {sub_period: period});
  }
}
