import React from 'react';

import {NoVNCConnection, ManageCloudInstance} from '@growth-x/ui';

export function AdminTools({
  client,
  isAdmin,
  clientInstance,
  getLoading,
  updateLoading,
  getCloudInstance,
  createCloudInstance,
  forceRebootCloudInstance,
  delayDeleteCloudInstance,
  deleteCloudInstance,
  setProgressPercentage,
  setProgressDone,
  getClient,
  startCloudInstance,
  getClientServerState,
  serverState,
}) {
  const isNoVncAvailable = client.c && (isAdmin || client.manage_cloud_feature);
  const isServerRunning = serverState === 'running';
  return (
    <div>
      {isAdmin && (
        <ManageCloudInstance
          clientInstance={clientInstance}
          getLoading={getLoading}
          updateLoading={updateLoading}
          createCloudInstance={createCloudInstance}
          forceRebootCloudInstance={forceRebootCloudInstance}
          delayDeleteCloudInstance={delayDeleteCloudInstance}
          deleteCloudInstance={deleteCloudInstance}
          getCloudInstance={getCloudInstance}
          setProgressPercentage={setProgressPercentage}
          setProgressDone={setProgressDone}
          getClient={getClient}
          getClientServerState={getClientServerState}
          serverState={serverState}
        />
      )}
      <div>
        {isNoVncAvailable && (
          <NoVNCConnection
            client={client}
            startCloudInstance={startCloudInstance}
            options={{
              showToolbar: true,
              autoConnect: false,
              disabled: getLoading || updateLoading,
              isServerRunning,
            }}
          />
        )}
      </div>
    </div>
  );
}
