import React from 'react';

import {Campaign} from '@growth-x/types';

import {isCampaignRetention} from '../../utils';
import {AcquisitionDetails} from './acquisitionDetails';
import {RetentionDetails} from './retentionDetails';
import './campaignDetails.css';

export type CampaignDetailsProps = {
  isCSVInvalid: (csv: File) => Promise<boolean>;
  onSubmit: (campaign: Campaign, form: any) => Promise<void>;
  onCancel: () => void;
  client: any;
  campaign: any;
  campaignProcessing: boolean;
  trackIntercomEvent: (tag: any, value: any, condition?: any) => void;
  error: string;
  campaignList: Campaign[];
  clientAttachments: any;
  campaignParsedFiltersLoading: boolean;
  campaignParsedFilters: any;
  apiUrl: string;
  createClientAttachment: (attachment: any) => Promise<void>;
  deleteClientAttachment: (attachment_id: string | number) => Promise<void>;
  getClientAttachment: (attachment_id: string | number) => Promise<any>;
  onParseSearchUrl: (url: string) => void;
  clientTags: any;
  getTags: () => void;
  resetSearchUrlFilters: () => void;
  templates: any;
  createTemplate: (message?: string) => void;
  moveFromPromoPlan: () => Promise<any>;
  InstantlyFormItemContainer: React.FunctionComponent<any>;
  getFollowupMessageSuggestion: (props: any) => any;
  isInstantlyVerified: boolean;
  rb2bLatestEvent: any;
  setHeaderModal: (modal: string) => void;
};

export const CampaignDetails = (props: CampaignDetailsProps) => {
  if (!props.campaign) return <div />;

  return isCampaignRetention(props.campaign) ? <RetentionDetails {...props} /> : <AcquisitionDetails {...props} />;
};
