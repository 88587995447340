import {brandConfig, BRANDS, IntercomServiceAbstract} from '@growth-x/ui';

export const INTERCOM_TOURS = {
  [BRANDS.GX]: {
    CREATE_CAMPAIGN: 517295,
    START_CAMPAIGNS: 517297,
    HOW_TO_CREATE_USER: 517296,
    CALL_TO_CREATE_USER: 525119,
  },
  [BRANDS.LEADOKU]: {
    CREATE_CAMPAIGN: 479869,
    START_CAMPAIGNS: 480671,
    HOW_TO_CREATE_USER: 479904,
    CALL_TO_CREATE_USER: 480680,
  },
  [BRANDS.DIGITALMARK]: {},
  [BRANDS.INFINITE]: {},
};

export class IntercomService extends IntercomServiceAbstract {
  static trackIntercomEvent = (tag: any, value: any, condition?: any) => {
    if (brandConfig.intercomFeature) {
      const canUseDom = !!(typeof window !== 'undefined' && window.document && window.document.createElement);
      if (canUseDom && (window as any).Intercom && (window as any).intercomSettings) {
        if (condition === undefined || condition) {
          (window as any).intercomSettings[tag] = value;
          (window as any).Intercom('update');
        }
      } else {
        console.warn('Intercom not initialized yet');
      }
    }
  };
  static startTour = (tourId: number) => {
    if (brandConfig.intercomFeature && tourId) {
      const canUseDom = !!(typeof window !== 'undefined' && window.document && window.document.createElement);
      if (canUseDom && (window as any).Intercom && (window as any).intercomSettings) {
        (window as any).Intercom('startTour', tourId);
      } else {
        console.warn('Intercom not initialized yet');
      }
    }
  };
  static showNewMessage = () => {
    if (brandConfig.intercomFeature) {
      const canUseDom = !!(typeof window !== 'undefined' && window.document && window.document.createElement);
      if (canUseDom && (window as any).Intercom && (window as any).intercomSettings) {
        (window as any).Intercom('showNewMessage');
      } else {
        console.warn('Intercom not initialized yet');
      }
    }
  };
}
