import {Badge, Icon, Menu} from 'antd';
import React, {useCallback, useMemo} from 'react';
import {Link} from 'react-router-dom';

import {findIconDefinition, IconLookup} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {User} from '@growth-x/types';
import {CONSTANTS, moveScrollBySelector, IconWrapper} from '@growth-x/ui';

const {SubMenu} = Menu;

export const InboxNavigation = ({
  page,
  client,
  users,
  unreadCountByUser,
  inboxLoading,
  selectedInboxUser,
  openKeys,
  setSettings,
}) => {
  const getUnreadCountForUser = useCallback(
    (user: User) => {
      const countItem = unreadCountByUser?.find((option: any) => String(option.user_id) === String(user.id));
      return countItem ? countItem.count : 0;
    },
    [unreadCountByUser]
  );
  const archivedUsers = useMemo(() => users.filter(user => user.archived), [users]);
  const unarchivedUsers = useMemo(() => users.filter(user => !user.archived), [users]);

  const onTitleClick = (keys: any) => {
    if (!openKeys.includes(keys.key)) {
      setSettings({openKeys: [...openKeys, keys.key]});
      moveScrollBySelector('.navigation');
    } else {
      setSettings({openKeys: [CONSTANTS.inbox_page_key]});
    }
  };
  return (
    <SubMenu
      className="navigation__inbox-submenu"
      key={page.key}
      title={
        <Link to={`/inbox/`}>
          {page.icon.prefix ? (
            <IconWrapper>
              <FontAwesomeIcon icon={findIconDefinition(page.icon as IconLookup)} />
            </IconWrapper>
          ) : (
            <Icon type={page.icon.iconName} />
          )}
          <span>{page.label}</span>
        </Link>
      }
    >
      {unarchivedUsers.map((user: User) => (
        <Menu.Item
          key={`${user.id}`}
          disabled={client.team_member && client.team_member.user && user.id !== client.team_member.user}
        >
          <Link to={`/inbox/${user.id}`}>
            {user.first_name || user.email} <Badge count={getUnreadCountForUser(user)} />{' '}
            {inboxLoading && selectedInboxUser === String(user.id) && <Icon type="loading" />}
          </Link>
        </Menu.Item>
      ))}
      {archivedUsers && archivedUsers.length > 0 && (
        <SubMenu
          onTitleClick={onTitleClick}
          key={page.key + '_archived'}
          title={
            <>
              <Icon type={page.icon.iconName} />
              <span>Archived</span>
            </>
          }
          className="navigation__inbox-submenu archived"
        >
          {archivedUsers.map((user: User) => (
            <Menu.Item
              className="navigation__inbox-submenu-item archived"
              key={`${user.id}`}
              disabled={client.team_member && client.team_member.user && user.id !== client.team_member.user}
            >
              <Link to={`/inbox/${user.id}`}>
                {user.first_name || user.email} <Badge count={getUnreadCountForUser(user)} />{' '}
                {inboxLoading && selectedInboxUser === String(user.id) && <Icon type="loading" />}
              </Link>
            </Menu.Item>
          ))}
        </SubMenu>
      )}
    </SubMenu>
  );
};
