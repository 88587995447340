import {
  GET_RB2B_LATEST_EVENT_REQUEST,
  GET_RB2B_LATEST_EVENT_FAILURE,
  GET_RB2B_LATEST_EVENT_SUCCESS,
} from './rb2bActions';

const initialState: any = {
  isLoading: false,
  event: null,
};

export const rb2bReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_RB2B_LATEST_EVENT_REQUEST: {
      return {...state, isLoading: true};
    }
    case GET_RB2B_LATEST_EVENT_SUCCESS: {
      return {...state, isLoading: false, event: action.data};
    }
    case GET_RB2B_LATEST_EVENT_FAILURE: {
      return {...state, isLoading: false, event: null};
    }
    default:
      return state;
  }
};
