import {Drawer, Icon, Menu} from 'antd';
import React, {useMemo} from 'react';
import {Link} from 'react-router-dom';

import {findIconDefinition, IconLookup} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {CONSTANTS, isClientTrial, IconWrapper} from '@growth-x/ui';

import './navigation.scss';
import {InboxNavigation as getInboxNavigation} from './inboxNavigation';

export const MobileNavigation = ({
  location,
  isNavCollapsed,
  setSettings,
  client,
  users,
  unreadCountByUser,
  inboxLoading,
  isAdmin,
  openKeys,
}: any) => {
  const pages = getPages(client, isAdmin);
  const filteredPathname = `/${location.pathname.split('/')[1]}`;
  // add selected inbox user
  const selectedInboxUser = location.pathname.split('/').pop();
  const selectedKeys = useMemo(() => {
    const array = pages.reduce(
      (acc, item) => (item.path === filteredPathname ? acc.concat(item.key) : acc),
      [] as string[]
    );
    if (array[0] === CONSTANTS.inbox_page_key) {
      array.push(selectedInboxUser);
    }
    return array;
  }, [pages, filteredPathname, selectedInboxUser]);

  return (
    <Drawer
      placement="left"
      className="mobile_menu_container"
      closable={false}
      onClose={() => setSettings({isNavCollapsed: !isNavCollapsed})}
      visible={!isNavCollapsed}
      getContainer={false}
      style={{position: 'absolute'}}
    >
      <Menu
        onSelect={() => setSettings({isNavCollapsed: !isNavCollapsed})}
        className="menu-tabs"
        mode="inline"
        theme="light"
        openKeys={openKeys}
        selectedKeys={selectedKeys}
      >
        {pages.map(page =>
          page.label === 'Inbox' ? (
            getInboxNavigation({
              page,
              client,
              users,
              unreadCountByUser,
              inboxLoading,
              selectedInboxUser,
              openKeys,
              setSettings,
            })
          ) : (
            <Menu.Item key={page.key}>
              <Link to={page.path}>
                {page.icon.prefix ? (
                  <IconWrapper>
                    <FontAwesomeIcon icon={findIconDefinition(page.icon as IconLookup)} />
                  </IconWrapper>
                ) : (
                  <Icon type={page.icon.iconName} />
                )}
                <span>{page.label}</span>
              </Link>
            </Menu.Item>
          )
        )}
      </Menu>
    </Drawer>
  );
};

function getPages(client: any, isAdmin: boolean) {
  const excludeList: any = [];
  const isTrialSubscription = isClientTrial(client.type);
  if (isTrialSubscription) {
    excludeList.push('Prospects');
  }
  if (!client.inbox_feature) {
    excludeList.push('Inbox');
  }
  if (!isAdmin) {
    excludeList.push('Audience');
  }
  return pageList.filter((page: any) => !excludeList.find((excludeLabel: any) => excludeLabel === page.label));
}

const pageList = [
  {label: 'Campaigns', path: '/', icon: {prefix: 'fas', iconName: 'list-ul'}, key: '1'},
  {label: 'Users', path: '/users', icon: {prefix: 'fas', iconName: 'users'}, key: '2'},
  {label: 'Inbox', path: '/inbox', icon: {prefix: 'fas', iconName: 'inbox'}, key: '5'},
  {label: 'Templates', path: '/templates', icon: {prefix: 'fas', iconName: 'file-alt'}, key: '6'},
];
