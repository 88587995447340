import {Select, Button, Tooltip, DatePicker} from 'antd';
import moment, {Moment} from 'moment';
import React from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconWrapper} from '@growth-x/ui';

const {Option} = Select;
const {RangePicker} = DatePicker;

const dateOptions = [
  {
    label: 'Lifetime',
    value: 'lifetime',
  },
  {
    label: 'Last 30 days vs last previous 30 days',
    value: 'last_30_days_vs_prev_30_days',
    className: 'campaigns-page__last30vsprev30-option',
    tooltip: 'Last 30 days vs last previous 30 days',
  },
  {
    label: 'Last 7 days',
    value: 'last_7_days',
  },
  {
    label: 'Last 30 days',
    value: 'last_30_days',
  },
  {
    label: 'Last 3 Months',
    value: 'last_3_months',
  },
  {
    label: 'Last 6 months',
    value: 'last_6_months',
  },
  {
    label: 'Last 12 months',
    value: 'last_12_months',
  },
];

export const DateFilter = ({
  handleChange,
  filters,
  clearAndClose,
  updateGraph,
  campaignsToRequest,
  filterCampaigns,
}) => {
  const [showCustom, setShowCustom] = React.useState(false);
  const [showDatesSelect, setShowDatesSelect] = React.useState(false);
  const lastTwoMonthRange: [Moment, Moment] = [moment().subtract(1, 'months'), moment()];
  const disableFutureDates = currentDate => currentDate && currentDate >= moment().endOf('day');

  const onChange = date => {
    setShowCustom(false);
    handleChange(date);
  };

  const customDates = arg => {
    setShowCustom(false);
    setShowDatesSelect(false);
    let [start, end] = arg;
    start = start.format('YYYY-MM-DD');
    end = end.format('YYYY-MM-DD');
    filterCampaigns({
      ...filters,
      date: `${start} - ${end}`,
      date_range_start: start,
      date_range_end: end,
    });
    updateGraph(campaignsToRequest);
  };
  return (
    <div className="campaigns-page__filter-wrapper">
      <IconWrapper>
        <FontAwesomeIcon icon={['fas', 'clock']} />
      </IconWrapper>
      <Select
        placeholder="Select time"
        open={showDatesSelect}
        value={filters.date}
        className="campaigns-page__filter-input"
        onDropdownVisibleChange={open => {
          if (open) {
            setShowDatesSelect(true);
          } else if (!open && !showCustom) {
            setShowDatesSelect(false);
          }
        }}
        onChange={onChange}
        dropdownRender={menu => (
          <div>
            {menu}
            <div
              className="custom-range"
              onMouseDown={e => e.preventDefault()}
              onBlur={() => {
                setShowDatesSelect(false);
                setShowCustom(false);
              }}
            >
              <div onClick={() => setShowCustom(true)} className="custom-range__button">
                Custom
              </div>
              <RangePicker
                className="custom-range__datepicker"
                open={showCustom}
                onChange={customDates}
                defaultValue={lastTwoMonthRange}
                disabledDate={disableFutureDates}
              />
            </div>
          </div>
        )}
      >
        {dateOptions.map(item =>
          item.tooltip ? (
            <Option className={item.className} key={item.value}>
              <Tooltip title={item.tooltip}>{item.label}</Tooltip>
            </Option>
          ) : (
            <Option className={item.className} key={item.value}>
              {item.label}
            </Option>
          )
        )}
      </Select>
      <Button onClick={clearAndClose} icon="close" type="link" className="campaigns-page__close-filter" size="small" />
    </div>
  );
};
