import {Select, Button} from 'antd';
import React from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconWrapper} from '@growth-x/ui';

import {getCampaignTypes, CONSTANTS} from '../../../../utils';

const {Option} = Select;

const CAMPAIGN_TYPES = [
  CONSTANTS.campaignTypes.acquisition,
  CONSTANTS.campaignTypes.acquisition_csv,
  CONSTANTS.campaignTypes.acquisition_via_import,
  CONSTANTS.campaignTypes.acquisition_message_request,
  CONSTANTS.campaignTypes.retention,
  CONSTANTS.campaignTypes.retention_csv,
  CONSTANTS.campaignTypes.retention_actions,
  CONSTANTS.campaignTypes.retention_tags,
  CONSTANTS.campaignTypes.acquisition_inmail,
];

export const CampaignTypesFilter = ({handleChange, filters, clearAndClose}) => {
  return (
    <div className="campaigns-page__filter-wrapper">
      <IconWrapper>
        <FontAwesomeIcon icon={['fas', 'object-ungroup']} />
      </IconWrapper>
      <Select
        mode="multiple"
        maxTagCount={1}
        placeholder="Select type"
        onChange={handleChange}
        value={filters.campaign_types}
        filterOption={(input, option) =>
          (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        className="campaigns-page__filter-input"
      >
        {CAMPAIGN_TYPES.map(type => (
          <Option key={type} className="ghp">
            {getCampaignTypes(type).value}
          </Option>
        ))}
      </Select>
      <Button onClick={clearAndClose} icon="close" type="link" className="campaigns-page__close-filter" size="small" />
    </div>
  );
};
